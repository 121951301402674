import React from "react";
import { useNavigate } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";
import "./register.css";

const RegisterFailure = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/", { state: { isPaymentSuccess: true } });
  };

  return (
    <>
      <div className="register_failure_page_main">
        <div className="register_failure_page">
          <div className="payment_fail_images new_fail_img">
            <img src="/NewImage/Payment-Failed.jpeg" alt="Payment failed img" />
          </div>
          <div className="faliure_page_buttons1">
            <h3>Payment Failed!</h3>
            <h6 style={{ color: "red", margin: "1rem 0rem" }}>Please Retry!</h6>
            <button onClick={handleClick} className="faled_crossBtn">
              OK
            </button>
            <button onClick={handleClick} className="faled_crossBtn ">
              <RxCrossCircled />{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterFailure;
