import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import "./visaApply.css";
import Header from "../Home/Header";
import "react-datepicker/dist/react-datepicker.css";
import Accordion from "react-bootstrap/Accordion";
import { countries } from "../countries";
import CardMedia from "@mui/material/CardMedia";
import { FaRupeeSign } from "react-icons/fa";
import { MdOutlineVerifiedUser } from "react-icons/md";
import Form from "react-bootstrap/Form";
import Footer from "../Footer/Footer.jsx";
import VisaCalcultor from "./VisaCalculator.jsx";
import { Helmet } from "react-helmet";

// colombia,angola,equitorial Guinea,qatar,rwanda,india

// Define the array of oppintment Date countries 👍
const appointmentCountries = [
  "Spain",
  "France",
  "Poland",
  "Austria",
  // "Romania",
  "Germany",
  "Italy",
  "Malta",
  "Belgium",
  "Czechia",
  "Estonia",
  "Finland",
  "Greece",
  "Hungary",
  "Liechtenstein",
  "Netherlands",
  "Norway",
  "Slovakia",
  "Slovenia",
  "Sweden",
  "Switzerland",
];
const appointmentUSA = ["United States"];
const multyEntryEgypt = ["Egypt"];

const VisaApplyPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // const [selectedDate, setSelectedDate] = useState(null);
  const [quantity, setQuantity] = useState(1);
  // const [futureDate, setFutureDate] = useState(null);
  const [country, setCountry] = useState(null);
  const [check, setCheck] = useState(false);
  // const [isEvisaCountry, setIsEvisaCountry] = useState(null);

  useEffect(() => {
    // Find the selected country by ID
    const selectedCountry = countries.find(
      (country) => country.id === parseFloat(id)
    );

    setCountry(selectedCountry);
  }, [id, countries]);
  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  //   const futureDate = new Date(date);
  //   futureDate.setDate(futureDate.getDate() + country.days);
  //   setFutureDate(futureDate);
  // };

  const handleStartApplication = () => {
    navigate("/Traveller-Detail", {
      state: { country },
    });
  };
  const handleAppointmentStartApplication = () => {
    const appointmentPrice = country.aPrice;
    navigate("/Traveller-Detail", {
      state: { country, appointmentPrice },
    });
  };
  const handleMultiEntryStartApplication = (multyEntryPrice,DewDays) => {
    navigate("/Traveller-Detail", {
      state: { country, multyEntryPrice,DewDays },
    });
  };

  // Compute the display text after the country has been set
  const displayText = country
    ? appointmentCountries.includes(country.name)
      ? "Appointment date"
      : "Visa response date"
    : "";

  const displayNewText = country ? (
    appointmentCountries.includes(country.name) ? (
      <>Appointment</>
    ) : (
      "Visa"
    )
  ) : (
    ""
  );
  // ********************************usa appointment**********************************
  const usaAppointment = country ? (
    appointmentUSA.includes(country.name) ? (
      <>Appointment by</>
    ) : (
      "Visa"
    )
  ) : (
    ""
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const currentUrl = window.location.href;

  return (
    <>
      <div className="ViaApply_Header">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>{country ? country.name : "Start-Application"}</title>
          <meta
            name="description"
            content="Need a visa? Apply for your visa with ease. Start your journey today!"
          />
          <meta
            name="keywords"
            content="work visa requirements, visa requirements by country, how to apply for a visa, tourist visa, work visa, student visa, and interview preparation.,"
          />
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </div>
      {country && (
        <div className="main-wrapper-visaPage">
          <div className="CountryName">{country.name}</div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="collumPadding">
                  <div className="card visaCard1">
                    <div className="card-img">
                      <h3 className="img-content">{country.tag}</h3>
                      <CardMedia
                        image={process.env.PUBLIC_URL + country.pic}
                        component="img"
                        loading="lazy"
                      />
                    </div>
                  </div>

                  <div className="Accordian_Box">
                    <div className="visaCard_content">
                      <p>
                        <span>Notice:</span> {country.notice}
                      </p>
                      <h4>Documents required</h4>
                    </div>
                    {country.docs.map((document, index) => (
                      <div className="accordian11" key={index}>
                        <Accordion defaultActiveKey={["0"]}>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              {document.heading}
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>{document.headContent}</p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: document.content,
                                }}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    ))}
                    <hr className="border_bottom" />
                    <div className="slectDate25">
                      <h4>
                        <MdOutlineVerifiedUser /> Bank Balance Calculator{" "}
                      </h4>
                      <div className="checkTotalAmount">
                        <Form.Check
                          aria-label="option 1"
                          onClick={() => setCheck(!check)}
                        />
                        <label htmlFor="#" className="labeCheck">
                          Ideal Bank Balance At The Time Of Filing Visas
                        </label>
                      </div>
                    </div>
                    <div className="visacalc">{check && <VisaCalcultor />}</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="collumPadding">
                  {country.id === 85 ? (
                    <>
                      <hr className="border_top" />
                      <div className="visa_colorBox">
                        <div className="cost122">
                          <h5>Your {usaAppointment} </h5>
                          <h5>
                            <FaRupeeSign /> 30000
                          </h5>
                        </div>
                        <hr className="border_bottom01" />
                        <div className="visaapplyCards00">
                          <div className="visaapplyCards1">
                            <ul>
                              <li style={{ marginBottom: "3px" }}>
                                Visa Types
                              </li>
                              <li>Stay duration</li>
                              <li> Visa Validity</li>
                              <li> Processing time</li>
                            </ul>
                          </div>
                          <div className="visaapplyCards1">
                            <ul>
                              <li style={{ marginBottom: "3px" }}>
                                {country.Vtype}
                              </li>
                              <li>{country.sDuration} </li>
                              <li>{country.vValidity} </li>
                              <li>60-90 Working Days</li>
                            </ul>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            className="btn btn-primary btn_primarybtn"
                            onClick={()=>handleMultiEntryStartApplication(30000,country.DewDays)}
                          >
                            Start Application
                          </button>
                        </div>
                      </div>
                      {/* <hr className="border_bottom" /> */}
                    </>
                  ) : (
                    ""
                  )}

                  <hr className="border_bottom01" />
                  
      {/* *****************************Every Country cards************************ */}
      
                  <div className="visa_colorBox">
                    <div className="cost122">
                      <h5>Your {displayNewText} in just </h5>
                      <h5>
                        <FaRupeeSign /> {country.price}
                      </h5>
                    </div>
                    <hr className="border_bottom01" />
                    <div className="visaapplyCards00">
                      <div className="visaapplyCards1">
                        <ul>
                          <li style={{ marginBottom: "3px" }}>Visa Types</li>
                          <li>Stay duration</li>
                          <li> Visa Validity</li>
                          <li> Processing time</li>
                        </ul>
                      </div>
                      <div className="visaapplyCards1">
                        <ul>
                          <li style={{ marginBottom: "3px" }}>
                            {country.Vtype}
                          </li>
                          <li>{country.sDuration} </li>
                          <li>{country.vValidity} </li>
                          <li>{country.pTime}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        className="btn btn-primary btn_primarybtn"
                        onClick={handleStartApplication}
                      >
                        Start Application
                      </button>
                    </div>
                  </div>

                  {/* ****************Business Visa Cards********** */}

                  {country.id === 25 ? (
                    <>
                      <hr className="border_top" />

                      <div className="visa_colorBox">
                        <div className="cost122">
                          <h5>{country.multiEntryHeading} </h5>
                          <h5>
                            <FaRupeeSign /> {country.mEntryPrice}
                          </h5>
                        </div>
                        <hr className="border_bottom01" />
                        <div className="visaapplyCards00">
                          <div className="visaapplyCards1">
                            <ul>
                              <li style={{ marginBottom: "3px" }}>
                                Visa Types
                              </li>
                              <li>Stay duration</li>
                              <li> Visa Validity</li>
                              <li> Processing time</li>
                            </ul>
                          </div>
                          <div className="visaapplyCards1">
                            <ul>
                              <li style={{ marginBottom: "3px" }}>
                                {country.Vtype}
                              </li>
                              <li>{country.sDuration} </li>
                              <li>{country.vValidity} </li>
                              <li>{country.pTime}</li>
                            </ul>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            className="btn btn-primary btn_primarybtn"
                            onClick={()=>handleMultiEntryStartApplication(5500)}
                          >
                            Start Application
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {/* ****************Business Visa Cards********** */}

                  {country.id === 85 ? (
                    <>
                      <hr className="border_top" />
                      <div className="visa_colorBox">
                        <div className="cost122">
                          <h5>Your {usaAppointment} </h5>
                          <h5>
                            <FaRupeeSign /> {country.aPrice * quantity}
                          </h5>
                        </div>
                        <hr className="border_bottom01" />
                        <div className="visaapplyCards00">
                          <div className="visaapplyCards1">
                            <ul>
                              <li style={{ marginBottom: "3px" }}>
                                Visa Types
                              </li>
                              <li>Get an appointment in</li>
                            </ul>
                          </div>
                          <div className="visaapplyCards1">
                            <ul>
                              <li style={{ marginBottom: "3px" }}>
                                Appointment card
                              </li>
                              <li>30-45 Days</li>
                            </ul>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            className="btn btn-primary btn_primarybtn"
                            onClick={handleAppointmentStartApplication}
                          >
                            Start Application
                          </button>
                        </div>
                      </div>
                      {/* <hr className="border_bottom" /> */}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default VisaApplyPage;
