import React, { useContext, useEffect } from "react";
import "./user.css";
import VisaContext from "../../../../context/visa-context";
import MyLoader from "../../../Loader/MyLoader";
import Header from "../../../Home/Header";

const UserApplicatons = () => {
  const ctxApi = useContext(VisaContext);


  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },[]);
  return (
    <>
      <div className="ViaApply_Header dashboard_header">
        <Header />
      </div>
      <div className="dashboard_heading">
        <main className="main-container">
          {ctxApi.isLoading && <MyLoader />}
          <div className="main-title11">
            <h3 style={{ color: "#13e313", fontSize: "25px" }}>
              Final Informations
            </h3>
          </div>
          <div className="userpage">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Last Name</th>
                  <th>Destination</th>
                  <th>Price</th>
                  <th>Selected Date</th>
                  <th>Response Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {ctxApi.applications.map((datas, ind) => (
                  <tr key={ind}>
                    <td>{datas.postData.name}</td>
                    <td>{datas.postData.lname}</td>
                    <td>{datas.postData.countryName}</td>
                    <td>{datas.postData.countryPrice}</td>
                    <td>{datas.postData.selectedDate}</td>
                    <td>{datas.postData.futureDate}</td>
                    <td className="status_data">{datas.postData.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </>
  );
};

export default UserApplicatons;
