import React, { useEffect } from "react";
import "./footer.css";
import Header from "../Home/Header";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const currentUrl = window.location.href;
  return (
    <>
      <div className="aboutpage">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>About-Us</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>
      <div className="container">
        <div className="row">
          <div className="aboutPage">
            <div className="aboutHeading">
              <h3>About Us</h3>
              <p>
                Welcome to <span>Vijatour.com</span>, Owned and operated by Groworld Vijatour Pvt Ltd, your trusted partner for
                fast, reliable, and affordable Visa  services. Our mission is to
                make your travel dreams come true by ensuring a hassle-free Visa 
                application process, guaranteeing timely responses, and
                providing complete transparency throughout.
              </p>
            </div>
            <div className="aboutHeading">
              <h4>Who We Are</h4>
              <p>
                At <span>Vijatour.com</span>, we are a dedicated team of Visa 
                experts with years of experience in the travel and immigration
                industry. We understand the complexities and challenges of
                obtaining a Visa , and we are committed to simplifying this
                process for you. Our passion for travel and commitment to
                excellence drive us to deliver top-notch service to all our
                clients.
              </p>
            </div>
            <div className="aboutHeading">
              <h6>Our Promise</h6>
              <h4>Guaranteed On-Time Visa  Response</h4>
              <p>
                We value your time and understand the importance of timely Visa 
                approvals. That's why we offer a guarantee on Visa  response
                times. If we do not meet the promised response time, we provide
                our services free of charge. This commitment ensures that you
                can plan your travels with confidence and peace of mind.
              </p>
            </div>
            <div className="aboutHeading">
              <h4>Transparent Process</h4>
              <p>
                Transparency is at the core of our operations. From the moment
                you contact us, you will receive clear and detailed information
                about the Visa  application process, required documents, fees,
                and timelines. There are no hidden charges or last-minute
                surprises—just honest and straightforward service.
              </p>
            </div>
            <div className="aboutHeading">
              <h4>Affordable Rates</h4>
              <p>
                Travel should be accessible to everyone. We strive to offer the
                most competitive rates in the market without compromising on the
                quality of our service. Our goal is to provide you with
                exceptional value, making your Visa  application process both
                cost-effective and stress-free.
              </p>
            </div>
            <div className="aboutHeading">
              <h6>Why Choose Us?</h6>
              <ol>
                <li>
                  <span>Expertise:</span> Our team comprises seasoned
                  professionals who are well-versed in the Visa  requirements of
                  various countries. We stay updated with the latest regulations
                  to ensure your application meets all necessary criteria.
                </li>
                <li>
                  <span>Customer-Centric Approach:</span> Your satisfaction is
                  our priority. We offer personalized assistance, addressing
                  your unique needs and concerns at every step of the process.
                </li>
                <li>
                  <span>Comprehensive Support:</span> From initial consultation
                  to final Visa  approval, we are with you every step of the way.
                  Our support extends beyond the application process, offering
                  guidance on travel preparations and any other queries you may
                  have.
                </li>
                <li>
                  <span>Innovative Solutions:</span> Utilizing advanced
                  technology, we streamline the Visa  application process, making
                  it more efficient and user-friendly. Our online tools and
                  resources are designed to save you time and effort.
                </li>
              </ol>
            </div>
            <div className="aboutHeading">
              <h6>Our Services</h6>
              <ul>
                <li>
                  <span>Visa  Consultation:</span> Personalized guidance to help
                  you understand Visa  requirements and prepare your application.
                </li>
                <li>
                  <span>Document Preparation:</span> Assistance with gathering
                  and organizing the necessary documents for your Visa 
                  application.
                </li>
                <li>
                  <span>Application Submission:</span>Efficient handling of your
                  application submission to ensure accuracy and compliance with
                  embassy requirements.
                </li>
                <li>
                  <span>Follow-Up: </span> Regular updates on the status of your
                  application and prompt response to any queries you may have.
                </li>
                <li>
                  <span>Additional Travel Services:</span> We also offer travel
                  insurance, flight bookings, and accommodation arrangements to
                  complement your Visa  services.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>     
    </>
  );
};

export default AboutUs;
