import React from "react";
import Header from "./Header";
import Cards from "./CountryCards/Cards";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

const Home = () => {
  const currentUrl = window.location.href;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vijatour-ON TIME VISA OR FREE</title>
        <meta
          name="description"
          content="Need a visa? Apply for your visa with ease. Our professional visa services offer quick and easy applications for tourist, work, and student visas. Start your journey today!"
        />
        <meta
          name="keywords"
          content="apply for a visa ,documents required for a visa, documents required for australia visa,
           documents required for canada visa, documents required for uk visa, documents required for schengen visa,
            documents required for singapore visa, documents required for qatar visa,
             documents required for uae visa, documents required for dubai visa, documents required for malaysia visa,
              documents required for philippines visa, documents required for china visa,
               documents required for russia visa, documents required for new zealand visa,
                documents required for usa visa, documents required for egypt visa,
                 documents required for sri lanka visa, documents required for south korea visa,
                  documents required for japan, documents required for germany, documents required for spain,
                   documents required for belgium, documents required for france, documents required for greece,
                   visa on arrival countries,how to travel without visa, how to become a travel agent, how to become a visa agent,
                    best visa agent in india, best visa agent in punjab, best visa agent in haryana, best visa agent, visa experts in india,
           how to apply for a visa, how to apply for australia visa,how to apply for Canada visa,how to apply for uk visa,
           how to apply for schengen visa,how to apply for Singapor visa,how to apply for qatar visa,
           how to apply for uae visa,how to apply for dubai visa,how to apply for malaysia visa,
           how to apply for philippines visa,how to apply for china visa,how to apply for russia visa,
           how to apply for new zealand visa, how to apply for usa visa,how to apply for egypt visa,
           how to apply for sri lanka visa,how to apply for south korea visa,how to apply for japan visa,
           how to apply for germany visa,how to apply for spain visa,how to apply for belgium visa,
           how to apply for france visa, how to apply for greece visa, free visa, apply for a tourist visa,
            free tourist visa,free visa agent course,free travel agent course,"
        />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="mian-wrapper">
        <div className="content">
          <Header />
          <Cards />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;
