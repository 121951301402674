import React, { useEffect } from "react";
import "../Home/CountryCards/card.css";
import { Link } from "react-router-dom";
import { countries } from "../countries";
import CardMedia from "@mui/material/CardMedia";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import Header from "../Home/Header";
import { Helmet } from "react-helmet";

const OtherCountris = () => {

  const allowedIds = [66
  ];
  const filteredCountries = countries.filter((country) =>
    allowedIds.includes(country.id)
  );
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const currentUrl = window.location.href;
  return (
    <>
      <div className="main-wrpa-otherCountry header">
      <Header/>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Other-Countries</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>
      <div className="countrisFilterById">
        <div className="card_container">
          <div className="row">
            {filteredCountries.map((country) => (
              <div className="col-lg-4 col-md-6 " key={country.id}>
                <Link to={`/Vijatour/${country.id}`} id="myButton">
                  <div className="card">
                    <div className="card-img">
                      <h3 className="img-content">
                        Visa  Response by{" "}
                        {country?.date ? (
                          <span style={{ marginLeft: "15px" }}>
                            {new Intl.DateTimeFormat("en-GB", {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                            }).format(new Date(country.date))}
                          </span>
                        ) : (
                          ""
                        )}
                      </h3>
                      <CardMedia
                        image={country.pic}
                        component="img"
                        loading="lazy"
                        alt={`${country.name} Visa `}
                      />
                    </div>
                    <div className="card_content">
                      <div className="country">{country.name}</div>
                      <div className="price">
                        <p>Price</p>
                        <MdOutlineCurrencyRupee /> {country.price}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherCountris;
