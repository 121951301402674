import React, { useEffect } from "react";
import Header from "../Home/Header";
import { Helmet } from "react-helmet";

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const currentUrl = window.location.href;
  return (
    <>
      <div className="aboutpage">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Refund-Policy</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>
      <div className="container">
        <div className="row">
          <div className="aboutPage">
            <div className="aboutHeading">
              <h3>Refund Policy</h3>
              <p >
                Our refund policy states that if the company fails to deliver
                the Visa  response or Visa  appointment on time, they will provide
                a 100% refund of the service charge to the customer. This policy
                assures customers that they will receive a full refund if the
                service is not delivered as promised, offering them peace of
                mind and confidence in the company's reliability.Here's a
                breakdown of our policy:
              </p>

              <ul>
                <li style={{fontSize:"14px"}}>
                  <span>On-Time Delivery Guarantee:</span>vijatour.com guarantees the delivery of Visa s on or before the specified date agreed upon during the application process.
                </li>
                <li style={{fontSize:"14px"}}>
                  <span>100% Refund Assurance:</span>If the Visa  is not delivered by the promised date, vijatour commits to providing a full refund of the service fees paid by the customer. This ensures that customers are not financially burdened if the service is not fulfilled as promised.
                </li>
                <li style={{fontSize:"14px"}}>
                  <span>Refund Process:</span>  If Visa  response/Visa  appointment is not delivered on time. Customer need to contact customer support, submit a refund request form, and follow specific instructions provided by the vijatour.com customer support agent.
                </li>
                <li style={{fontSize:"14px"}}>
                  <span>Timely Refund Processing:</span> All refund requests are processed promptly to minimize inconvenience to the customer.An estimated timeframe of 3-7 working days is required,when a customers can expect to receive their refund.
                </li>
                <li style={{fontSize:"14px"}}>
                  <span>Customer Support:</span> All customer support channels are open 24*7 for clarification regarding the refund policy and process.
                </li>
              </ul>
              <p style={{fontSize:"16px", fontWeight:"500"}}>This refund policy demonstrates the company's commitment to customer satisfaction and accountability for their services.</p>
            </div>         
          
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundPolicy;
