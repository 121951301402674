import React, { useEffect, useState } from "react";
import Header from "../../../../../Home/Header";
// // Strper
import Stepper from "@mui/joy/Stepper";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import axios from "axios";

const ApplicantCheckOutPage = () => {

  const loc = useLocation();
  const { state } = loc;
  const {
    workData = [],
    country = {}
  } = state || {};  


  // PhonePay Payment gateway 💯 👍 💯 👍

// const VisaPrice = updatedAmount !== null ? updatedAmount : countryPrice;
const number = workData.length;
const rupee = country.FirstFees * number;
const data = {
  amount: parseInt(rupee),
};

const handlePaymentGetway = async (e) => {
  e.preventDefault();
  try {
    const res = await axios.post('https://backend-visa2.vercel.app/api/applicantPayment/applicant-Payment', { ...data });      

    if (res.data && res.data.data.instrumentResponse.redirectInfo.url) {      
      window.location.href = res.data.data.instrumentResponse.redirectInfo.url;
    }
  } catch (error) {
    if (error.response) {
      console.error('Response error details:', error.response.data);
    } else {
      console.error('Error in payment gateway:', error.message);
    }
  }
};


useEffect(() => {
  const userId = localStorage.getItem('userId');
  const workVisaId = JSON.parse(sessionStorage.getItem("workVisaId")) || [];

  // Apply date formatting
  const applyDate = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(new Date());

  // Map workData to the desired structure
  const data = workData.map((applicant, index) => ({
    name: applicant.name,
    lname: applicant.lname,
    countryName: country.name,        // Assuming country is an object
    countryPrice: country.FirstFees,  // Assuming country is an object
    ApplyDate: applyDate,
    userId: userId,
    id: workVisaId[index]
  }));

  // Save data in sessionStorage
  const storedData = sessionStorage.getItem("workVisa");
  if (!storedData || storedData !== JSON.stringify(data)) {
    sessionStorage.setItem("workVisa", JSON.stringify(data));
  }
}, [workData, country.name, country.FirstFees]);
  
// PhonePay Payment gateway 💯 👍
useEffect(() => {
  window.scrollTo({ top: 0, behavior: "smooth" });
}, []);

  const currentUrl = window.location.href;
  return (
    <>
      <div className="mainwrapper main_checkoutPage">
      <div className="ViaApply_Header trevellerHead">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Checkout</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>
      <div className="container stepper0144">
        <Stepper size="sm">
          <Step
            indicator={
              <StepIndicator
                variant="solid"
                sx={{ backgroundColor: "red", color: "white" }}
              >
                1
              </StepIndicator>
            }
          >
            Step 1
          </Step>
          <Step
            indicator={
              <StepIndicator
                variant="solid"
                sx={{ backgroundColor: "red", color: "white" }}
              >
                2
              </StepIndicator>
            }
          >
            Step 2
          </Step>        
        </Stepper>
      </div>
      <div>
        <div className="addOnsFullPage">
          <div className="ReviewPayment">
            <form className="mainformAdd">
              <h2>Review Payment</h2>
              <div>
                {workData.map((Tdata, inde) => (
                  <div className="DummyFlightTicket">
                    <h5>
                      Applicant{" "}
                      <span
                        style={{
                          fontWeight: "500",
                          marginLeft: "5px",
                          color: "red",
                        }}
                      >
                        {inde + 1}
                      </span>{" "}
                    </h5>
                    <span className="DestinatioCont"
                     key={inde}
                     >
                      {Tdata.name} {Tdata.lname}{" "}
                    </span>
                  </div>
                ))}
              </div>
              <div className="DummyFlightTicket">
                <h5>Destination</h5>
                <span className="DestinatioCont">{country.name} </span>
              </div>
              <div className="DummyFlightTicket">
                <h5>Total Price</h5>
                <span className="DestinatioCont">
                  {" "}
                  {rupee}                
                </span>
              </div>
            </form>
            <div className="PaymentBtn">
              <button className="btn"
               onClick={handlePaymentGetway}
               >
                Pay Now
              </button>
            </div>
          </div>
        </div>      
      </div>
      </div>
    </>
  )
}

export default ApplicantCheckOutPage
