import React, { useContext, useEffect } from "react";
import Header from "../../../Home/Header";
import MyLoader from "../../../Loader/MyLoader";
import VisaContext from "../../../../context/visa-context";
import { FaFileDownload } from "react-icons/fa";

const DownloadAddOnsFils = () => {

    const ctxApi = useContext(VisaContext);
    const addOnsFiles = ctxApi.addOnsFiles;  
    const downloadFile = async (filename) => {
      try {
        const response = await fetch(`https://backend-visa2.vercel.app/api/addOns/get-downloadFile?filename=${encodeURIComponent(filename)}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
  
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          const text = await response.text();
          throw new Error(`Expected JSON, but got: ${text}`);
        }
  
        const data = await response.json();
        const downloadURL = data.downloadURL;
        const link = document.createElement("a");
        link.href = downloadURL;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    };


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
  return (
    <>
      <div className="ViaApply_Header dashboard_header">
        <Header />
      </div>
      <div className="dashboard_heading">
        <main className="main-container">
          {ctxApi.isLoading && <MyLoader />}
          <div className="main-title11">
            <h3 style={{ color: "#13e313", fontSize: "25px" }}>Add Ons Files</h3>
          </div>
          <div className="userpage">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Destination</th>
                  <th>Files</th>
                </tr>
              </thead>
              <tbody>
                {addOnsFiles.map((datas, ind) => (
                  <tr key={ind}>
                    <td>{datas.name}</td>
                    <td>{datas.destination}</td>
                    <td>
                      {datas.fileName}
                      <span className="userVisa_download">
                        <FaFileDownload
                          onClick={(e) => downloadFile(datas.originalName)}
                        />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </>
  );
};

export default DownloadAddOnsFils;
