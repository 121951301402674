import React, { useContext, useEffect, useState } from "react";
// import Modal from "react-bootstrap/Modal";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { validateFormLogin } from "../../../util/validation.js";
import VisaContext from "../../../context/visa-context.js";
import MyLoader from "../../Loader/MyLoader.jsx";

const LoginForm = () => {
  const navigate = useNavigate();
  const ctx = useContext(VisaContext);
  const [loginData, setloginData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [invalide, setInvalid] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setloginData({ ...loginData, [name]: value });
  };

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    ctx.setLoading(true);
    const newErrors = validateFormLogin(loginData);
    setErrors(newErrors);

    const isValid = Object.keys(newErrors).length === 0;
    if (isValid) {
      try {
        const response = await fetch(
          "https://backend-visa2.vercel.app/api/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(loginData),
          }
        );
        if (response.ok) {
          ctx.setLoading(false);
          const data = await response.json();
          localStorage.setItem("token", data.token);
          const id = localStorage.getItem("userId");
          if (id !== data.userId) {
            localStorage.removeItem("userId");
            localStorage.setItem("userId", data.userId);
          }
          ctx.setLogin(true);
          navigate('/')
      
          // Directly navigate to the TravelDetails route
          ctx.setLogin(true);
        } else {
          const errorData = await response.json();
          setInvalid(errorData.message);
          ctx.setLoading(false);
        }
      } catch (error) {
        ctx.setLoading(false);
        console.log(error);
      }

      setloginData({
        email: "",
        password: "",
      });
    } else {
      ctx.setLoading(false);
    }

    return isValid;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="loginFormCloseBtn">
        {ctx.isLoading && <MyLoader />}
        <div className="container">
          <div className="main_wrapper_register">
            <div className="googleLogin11 loginPage_login">
              <div closeButton className="crossButton">
                <div className="loginHead">
                  <h2 style={{color:"white"}}>Login</h2>
                </div>
              </div>
            </div>
            <div>
              <div className="googleLogin loginPage_login">
                <p className="create_your_account">Login to Your Account</p>
                <form method="POST">
                  <div className="input_group mb-4">
                    <input
                      className="inputPass"
                      onChange={handleChange}
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={loginData.email}
                    />
                    {errors.email && <p className="style01">{errors.email}</p>}
                  </div>
                  <div className="input_group ">
                    <input
                      className="inputPass"
                      onChange={handleChange}
                      type="password"
                      name="password"
                      placeholder="password"
                      value={loginData.password}
                    />
                    {errors.password && (
                      <p className="style01">{errors.password}</p>
                    )}
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginBottom: "0rem",
                      }}
                    >
                      {invalide}
                    </p>
                  </div>
                  <div className="  checkRow checkRow04">
                    <Link to={"/request-reset-password"}>Forgot Password?</Link>
                  </div>
                  <button
                    onClick={handleSubmitLogin}
                    className="btn56"
                    type="submit"
                  >
                    Login
                  </button>
                </form>
                <div className="bottomLi">
                  <div className="bottomLine">
                    <span>OR</span>
                  </div>
                </div>
                <div className="signupButtons">
                  <Link className="btn" to={"/register"}>
                    Don't Have An Account? Sign Up
                  </Link>
                </div>
                <div className="provacyPage">
                  <p>
                    By Signing In, You Agree To Our
                    <Link>{"  "} Terms And Conditions</Link> And Acknowledge
                    That You Have Read Our <Link>{"  "} Privacy Policy.</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;

