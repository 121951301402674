
import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import VisaContextProvider from "./context/VisaContextProvider";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  // Use hydrateRoot for server-side rendered applications
  hydrateRoot(
    rootElement,
    <React.StrictMode>
      <VisaContextProvider>
        <App />
      </VisaContextProvider>
    </React.StrictMode>
  );
} else {
  // Use createRoot for client-side rendered applications
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <VisaContextProvider>
        <App />
      </VisaContextProvider>
    </React.StrictMode>
  );
}
