import React from "react";
import "./footer.css";
import Header from "../Home/Header";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const TermAndConditions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const currentUrl = window.location.href;
  return (
    <>
      <div className="aboutpage">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Term-And-Conditions</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>
      <div className="container">
        <div className="row">
          <div className="aboutPage">
            <div className="aboutHeading">
              <h3>Terms and Conditions</h3>
              <h4>Welcome to VijaTour!</h4>
              <p>
                These terms and conditions outline the rules and regulations for
                the use of VijaTour's website, located at <span style={{color:"red"}}>https://vijatour.com</span>.
                By accessing this website, we assume you accept these terms and
                conditions. Do not continue to use VijaTour if you do not agree
                to all of the terms and conditions stated on this page.
              </p>
            </div>
            <div className="aboutHeading">
              <h4>Terminology</h4>
              <p>
                The following terminology applies to these Terms and Conditions,
                Privacy Statement, and Disclaimer Notice and all Agreements:
              </p>
              <ul>
                <li>
                  <span>Client,</span> You, and Your refer to you, the person
                  who logs on to this website and is compliant with the
                  Company's terms and conditions.
                </li>
                <li>
                  <span>Customer-Centric Approach:</span> The Company,
                  Ourselves, We, Our, and Us refer to our Company.
                </li>
                <li>
                  <span>Party, Parties,</span> or Us refer to both the Client
                  and ourselves.
                </li>
              </ul>
            </div>
            <div className="aboutHeading">
              <h4>Services</h4>
              <p>
                VijaTour provides education and Visa  services with a guarantee
                of timely delivery. Our services include but are not limited to:
              </p>
              <ul>
                <li>
                  Education consultancy and advisoryEducation consultancy and
                  advisory
                </li>
                <li>Visa  application and processing</li>
                <li>
                  Additional support services related to education and Visas
                </li>
              </ul>
            </div>
            <div className="aboutHeading">
              <h4>Use of the Website</h4>
              <p>
                By using our website, you agree to comply with the following:
              </p>
              <ol>
                <li>
                  <span>Content:</span> The content provided on this website is
                  for your general information and use only. It is subject to
                  change without notice.
                </li>
                <li>
                  <span> Unauthorized Use:</span> Unauthorized use of this
                  website may give rise to a claim for damages and/or be a
                  criminal offense.
                </li>
                <li>
                  <span>Linking to Other Websites:</span> This website may
                  include links to other websites. These links are provided for
                  your convenience to provide further information. They do not
                  signify that we endorse the website(s). We have no
                  responsibility for the content of the linked website(s).
                </li>
              </ol>
            </div>
            <div className="aboutHeading">
              <h4>User Account</h4>
              <p>
                If you create an account on our website, you are responsible for
                maintaining the confidentiality of your account and password and
                for restricting access to your computer. You agree to accept
                responsibility for all activities that occur under your account
                or password.
              </p>
            </div>
            <div className="aboutHeading">
              <h4>Payment and Refund Policy</h4>
              <ol>
                <li>
                  <span>Payment:</span> All services provided by VijaTour are
                  subject to payment before the services are rendered. Payment
                  methods accepted will be indicated on the website.
                </li>
                <li>
                  <span> Refunds:</span> Refund policies vary based on the
                  service provided. Please refer to the specific service
                  agreement for detailed information.
                </li>
              </ol>
            </div>

            <div className="aboutHeading">
              <h4>Guarantee</h4>
              <p>
                VijaTour guarantees timely delivery of services as per the
                agreed timelines. However, delays caused by unforeseen
                circumstances beyond our control, including but not limited to
                government regulations, natural disasters, or technical issues,
                do not fall under this guarantee.
              </p>
            </div>
            <div className="aboutHeading">
              <h4>Intellectual Property</h4>
              <p>
                Unless otherwise stated, VijaTour and/or its licensors own the
                intellectual property rights for all material on VijaTour. All
                intellectual property rights are reserved. You may access this
                from VijaTour for your own personal use subjected to
                restrictions set in these terms and conditions.
              </p>
            </div>
            <div className="aboutHeading">
              <h4>Prohibited Uses</h4>
              <p>
              You may not use the website for any purpose that is unlawful or prohibited by these terms, conditions, and notices. You may not use the website in any manner that could damage, disable, overburden, or impair the website or interfere with any other party's use and enjoyment of the website.
              </p>
            </div>

            <div className="aboutHeading">
              <h4>Limitation of Liability</h4>
              <p>
              In no event shall VijaTour, nor any of its officers, directors, employees, agents, or suppliers, be liable to you for anything arising out of or in any way connected with your use of this website, whether such liability is under contract, tort, or otherwise, and VijaTour, including its officers, directors, employees, agents, or suppliers, shall not be liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this website.
              </p>
            </div>
            <div className="aboutHeading">
              <h4>Indemnification</h4>
              <p>
              You hereby indemnify to the fullest extent VijaTour from and against any and all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these terms.
              </p>
            </div>
            <div className="aboutHeading">
              <h4>Changes to Terms</h4>
              <p>
              VijaTour reserves the right, in its sole discretion, to change the Terms under which <span style={{color:'red'}}> https://vijatour.com</span> is offered. The most current version of the Terms will supersede all previous versions. VijaTour encourages you to periodically review the Terms to stay informed of our updates.
              </p>
            </div>

            <div className="aboutHeading">
              <h4>Contact Information</h4>
              <p>VijaTour welcomes your questions or comments regarding these Terms:</p>
              <ul>
                <li>
                  <span>Email:</span> <strong style={{color:'red'}}> support@vijatour.com</strong>
                </li>
                <li>
                  <span> Address:</span> <strong style={{color:'red'}}>First Floor,Besides Sadar Thana,Delhi Rohtak Corridor,opp metro pillar no 830, Bahadurgarh,Haryana -124507</strong>
                </li>
              </ul>
              <p>By using this website, you agree to the terms and conditions set out above.</p>
            </div>
        
          </div>
        </div>
      </div>
    </>
  );
};

export default TermAndConditions;
