export const WorkPermitCountries = [
  {
    id: 1,
    name: "Greece",
    Job: "AirPort Job",
    pic: "/NewImage/Greece-WorkPermit.avif",
    price: "550000",
    FirstFees: "1",
    days: 14,
    jobCards: [
      {
        id: 1,
        Country: "Greece",
        Job: "AirPort Job (Baggage Handler)",
        Salary: "950 EURO + OT",
        FirstFees: "1",
        Accomodation: "Provided by Company",
        skill: "English skill more than average",
        VisaFee: "Included",
        FlightT: "Included",
        PTime: "4 TO 6 Months",
        SC: "550000",
        Interview: "Required",
        Contract: "5 Year",
      },
    ],
  },
  {
    id: 2,
    name: "Cyprus",
    Job: "Welder",
    pic: "/NewImage/Nicosia-Cyprus-aerial-view.png",
    price: "350000",
    FirstFees: "1",
    days: 14,
    jobCards: [
      {
        id: 2,
        Country: "Cyprus",
        Job: "Welder",
        Salary: "1300 EURO ",
        FirstFees: "1",
        Accomodation: "Provided by Company",
        VisaFee: " Included",
        FlightT: " Included",
        PTime: "3 TO 4 Months",
        SC: "350000",
        Interview: "Required",
        Contract: "2 Year + Renewable",
      },
    ],
  },
  {
    id: 3,
    name: "Cyprus",
    Job: "Customer Care",
    pic: "/NewImage/cyprus3_workpermit.webp",
    price: "350000",
    days: 14,
    jobCards: [
      {
        id: 3,
        Country: "Cyprus",
        Job: "Customer Care Representative",
        Salary: " € 900 - € 1100 ",
        Accomodation: "Provided by Company",
        VisaFee: "Not Included",
        FlightT: "Not Included",
        PTime: "4 TO 6 Months",
        SC: "350000",
        Interview: "Required",
        Contract: "2 Year ",
        Experience: "Customer service, International Call center"
      },
    ],
  },
  {
    id: 4,
    name: "Germany",
    Job: "Healthcare Industry",
    pic: "/NewImage/warum-studieren-in-germany.webp",
    price: "350000",
    FirstFees: "1",
    days: 14,
    jobCards: [
      {
        id: 4,
        Country: "Germany",
        Job: "Healthcare Industry",
        Salary: " € 1100 - € 1300 ",
        Accomodation: "Provided by Company",
        VisaFee: "Not Included",
        FlightT: "Not Included",
        PTime: "2 TO 3 Months",
        SC: "350000",
        Interview: "Required",
        Contract: "3 Year ",
      },
    ],
  },
  {
    id: 5,
    name: "Germany",
    Job: "Hospitality",
    pic: "/NewImage/television-tower-berlin-germany-workPermit.jpeg",
    price: "350000",
    FirstFees: "1",
    days: 14,
    jobCards: [
      {
        id: 5,
        Country: "Germany",
        Job: "Hospitality",
        Salary: " € 1100 - € 1300 ",
        Accomodation: "Provided by Company",
        VisaFee: "Not Included",
        FlightT: "Not Included",
        PTime: "2 TO 3 Months",
        SC: "350000",
        Interview: "Required",
        Contract: "3 Year ",
      },
    ],
  },
 
];
