import React, { useContext, useEffect, useState } from "react";
import "./TravelerDetails.css";
import { IoMdAddCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { RiInformation2Line } from "react-icons/ri";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";

import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import VisaContext from "../../../context/visa-context";
import { validTrevellers } from "../../../util/validation.js";
import { BsCurrencyRupee } from "react-icons/bs";
import Header from "../../Home/Header.jsx";
// ********************************CALLENDAR**********************************
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// Strper
import Stepper from "@mui/joy/Stepper";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
// **********
import TextField from "@mui/material/TextField";
import { Helmet } from "react-helmet";

// Define the array of oppintment Date countries 👍
const appointmentCountries = [
  "Spain",
  "France",
  "Poland",
  "Austria",
  "Romania",
  "Germany",
  "Italy",
  "Malta",
  "Belgium",
  "Czechia",
  "Estonia",
  "Finland",
  "Greece",
  "Hungary",
  "Liechtenstein",
  "Netherlands",
  "Norway",
  "Slovakia",
  "Slovenia",
  "Sweden",
  "Switzerland",
];

const TravelerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ctx = useContext(VisaContext);
  const { state } = location;
  const { country, appointmentPrice, multyEntryPrice, DewDays } = state || {};
  const userId = localStorage.getItem("userId");
  const [errors, setErrors] = useState([]);
  const [travelers, setTravelers] = useState([
    {
      name: "",
      lname: "",
      email: "",
      contactNo: "",
      dob: null,
      passportNo: "",
      expectedDate: null,
      details: "",
      IdMark: "",
    },
  ]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [responseDate, setresponseDate] = useState(null);
  const [USresponseDate, setUSresponseDate] = useState(null);
  const CPrice = parseFloat(
    appointmentPrice
      ? appointmentPrice // If `appointmentPrice` is available, use it
      : multyEntryPrice // Else, if `multyEntryPrice` is available, use it
      ? multyEntryPrice
      : country?.price // Else, fallback to `country.price`
  );
  
  const [cCode, setCCode] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState(CPrice);
  const [errorMessage, setErrorMessage] = useState("");
  const [notify, setNotify] = useState("");
  // const [check, setCheck] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  // DUBAI COUPAN CODE 👍
  const dubaiCouponCode = "GETUAE10";
  const secondDubaiCouponCode = "CORPORATE";
  const currentDate = dayjs();
  // Current Date and visa response date
  useEffect(() => {
    const date = new Date();
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    setSelectedDate(formattedDate);
  }, []);

  // Display the future date based on country.days
  useEffect(() => {
    // Check if country exists and country.days is a valid number
    if (country && typeof country.days === "number" && !isNaN(country.days)) {
      const responseDate = new Date();
      responseDate.setDate(responseDate.getDate() + country.days);
      const options = { day: "numeric", month: "short", year: "numeric" };
      const formattedresponseDate = responseDate.toLocaleDateString(
        "en-GB",
        options
      );
      setresponseDate(formattedresponseDate);
    } else {
      // Handle cases where country.days is invalid or country doesn't exist
      console.error("Invalid country or country.days value:", country?.days);
      setresponseDate("Invalid date");
    }
  }, [country]);
 

  // 30000 sticker visa response date*********************************
  useEffect(() => {
    // Check if country exists and country.days is a valid number
    if (
      DewDays &&
      DewDays !== undefined &&
      typeof DewDays === "number" &&
      !isNaN(DewDays)
    ) {
      const responseDate = new Date();
      responseDate.setDate(responseDate.getDate() + DewDays);
      const options = { day: "numeric", month: "short", year: "numeric" };
      const formattedresponseDate = responseDate.toLocaleDateString(
        "en-GB",
        options
      );
      setUSresponseDate(formattedresponseDate);
    } else {
      // Handle cases where country.days is invalid or country doesn't exist
      console.error(" DewDays value not Provided:", DewDays);
      setUSresponseDate("Invalid date");
    }
  }, [DewDays]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newTravelers = [...travelers];
    newTravelers[index][name] = value;
    setTravelers(newTravelers);
  };

  const DobHandlechange = (date, index) => {
    const newTravelers = [...travelers];
    newTravelers[index].dob = date;
    setTravelers(newTravelers);
  };
  const ExpectedDateHandlechange = (date, index) => {
    const newTravelersExDate = [...travelers];
    newTravelersExDate[index].expectedDate = date;
    setTravelers(newTravelersExDate);
  };
  const [count, setCount] = useState(1);

  const addPerson = () => {
    setTravelers([
      ...travelers,
      {
        name: "",
        lname: "",
        email: "",
        contactNo: "",
        dob: null,
        passportNo: "",
        expectedDate: null,
        details: "",
        IdMark: "",
      },
    ]);
    setCount(count + 1);
  };

  const removePerson = (index) => {
    if (travelers.length > 1) {
      const newTravelers = [...travelers];
      newTravelers.splice(index, 1);
      setTravelers(newTravelers);
    }
  };
  const futureDate = appointmentPrice
  ? country.date
  : USresponseDate && USresponseDate !== "Invalid date"
  ? USresponseDate
  : responseDate
  const handleSubmitTraveler = async (e) => {
    e.preventDefault();

    // Validate the form data
    const newErrors = validTrevellers(travelers);
    setErrors(newErrors);
    // Check if there are any errors
    const hasErrors = newErrors.some(
      (personErrors) => Object.keys(personErrors).length > 0
    );
    if (!hasErrors) {
      // Generate random IDs for new travelers
      const newTravelerIds = travelers.map(() =>
        Math.random().toString(36).substring(7)
      );
      // Prepare formData with new traveler IDs
      const formData = travelers.map((person, index) => ({
        name: person.name,
        lname: person.lname,
        email: person.email,
        contactNo: person.contactNo,
        dob: person.dob
          ? dayjs(person.dob).startOf("day").format("YYYY-MM-DD")
          : null,
        passportNo: person.passportNo,
        expectedDate: person.expectedDate
          ? dayjs(person.expectedDate).startOf("day").format("YYYY-MM-DD")
          : null,
        details: person.details,
        IdMark: person.IdMark,
        photo: [],
        passport: [],
        otherDocuments: [],
        destination: country ? country.name : "",
        userId: userId,
        id: newTravelerIds[index], // Use the generated ID for each traveler
      }));

      try {
        const response = await fetch(
          "https://backend-visa2.vercel.app/api/traveler",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (response.ok) {
          // Clear existing traveler IDs in localStorage
          localStorage.setItem("travelerIds", JSON.stringify(newTravelerIds));

          const data = await response.json();
          ctx.setTravellerData(data);
          localStorage.setItem("traveller", JSON.stringify(data));
          navigate("/Document", {
            state: {
              travelers,
              discountedPrice,
              country,
              futureDate,
              selectedDate,
            },
          });
        } else {
          const errorData = await response.json();
          console.error("Failed to send Traveler data:", errorData);
        }
      } catch (error) {
        console.error("Error during send traveler data:", error);
      }

      // Reset travelers state after submission
      setTravelers([
        {
          name: "",
          lname: "",
          email: "",
          contactNo: "",
          dob: null,
          passportNo: "",
          expectedDate: null,
          details: "",
          IdMark: "",
        },
      ]);
    }
  };

  const handleCouponCodeChange = (e) => {
    e.preventDefault();
    setCCode(e.target.value);
  };

  const applyCouponCode = () => {
    const validCoupon = "WORLD10"; // Example coupon code for all countries

    if (cCode.trim() === validCoupon) {
      // Apply discount for the valid coupon
      const discounted = country?.PriceBreakage?.SCharge
        ? country?.PriceBreakage?.SCharge * 0.9 // 10% discount
        : null;

      const totalPrice =
        parseFloat(country?.PriceBreakage?.AFees) + parseFloat(discounted);

      setDiscountedPrice(totalPrice ? totalPrice : CPrice);
      setCCode(""); // Reset coupon code input
      setNotify("Coupon Code Added"); // Notify the user
      setErrorMessage("");
    } else {
      // Invalid coupon code
      setErrorMessage("Invalid coupon code");
      setNotify("");
    }
  };

  const removeCouponCode = () => {
    const CSCharge = country?.PriceBreakage?.SCharge
      ? parseFloat(country?.PriceBreakage?.SCharge)
      : null;
    const CACharge = country?.PriceBreakage?.AFees
      ? parseFloat(country?.PriceBreakage?.AFees)
      : null;

    // Calculate the original price
    const originalPrice =
      (CSCharge !== null ? CSCharge : 0) + (CACharge !== null ? CACharge : 0);

    // Set the discounted price or fallback to CPrice
    setDiscountedPrice(originalPrice > 0 ? originalPrice : CPrice);
    // setCheck(false);
    setErrorMessage("");
    setNotify("Coupon Code Removed");
  };

  // const handleCheckbox = () => {
  //   setCheck((prevCheck) => {
  //     const newCheck = !prevCheck;
  //     if (newCheck && country.name === "UAE") {
  //       const discounted = 5500;
  //       const totalPrice =
  //         parseFloat(country?.PriceBreakage?.AFees) + discounted;
  //       setDiscountedPrice(totalPrice ? totalPrice : CPrice);
  //       setNotify("Discounted Price for UAE");
  //       setErrorMessage("");
  //     } else {
  //       if (country.name === "UAE") {
  //         setDiscountedPrice(CPrice);
  //       }
  //       setNotify("");
  //     }
  //     return newCheck;
  //   });
  // };

  // HtmlTooltip style  💯
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      padding: "19px",
    },
  }));

  // Compute the display text after the country has been set
  const displayText = country
    ? appointmentCountries.includes(country.name)
      ? "Appointment date"
      : "Visa response date"
    : "";

  const usaAppointment = "Appointment Date By";

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const currentUrl = window.location.href;

  return (
    <>
      <div className="ViaApply_Header trevellerHead">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Traveller-Detail</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>
      <div className="container main_pageLogin">
        <div className="stepper0144">
          <Stepper size="sm">
            <Step
              indicator={
                <StepIndicator
                  sx={{ backgroundColor: "red", color: "white" }}
                  variant="solid"
                >
                  1
                </StepIndicator>
              }
            >
              Step 1
            </Step>
            <Step indicator={<StepIndicator variant="solid">2</StepIndicator>}>
              Step 2
            </Step>
            <Step indicator={<StepIndicator variant="solid">3</StepIndicator>}>
              Step 3
            </Step>
          </Stepper>
        </div>
        <div className=" loginForm">
          <div className="row loginform_style">
            <div className="col-md-6 mb-4 custom-col">
              <div className="card ripe-malinka-gradient form-white">
                <div className="card-body">
                  <form>
                    {travelers.map((traveler, index) => (
                      <div key={index}>
                        <h2 className="text-center font-up font-bold  travelHead">
                          Traveller Details <span>{index + 1}</span>
                        </h2>
                        <div className="row ">
                          <div className="md-form mdForm_margin col-lg-6">
                            <input
                              type="text"
                              id={`orangeForm-name-${index}`}
                              className="form-control"
                              placeholder=" First Name"
                              value={traveler.name}
                              name="name"
                              onChange={(e) => handleChange(e, index)}
                            />
                            {errors[index] && errors[index].name && (
                              <p
                                className="style01"
                                style={{ fontSize: "14px" }}
                              >
                                {errors[index].name}
                              </p>
                            )}
                          </div>
                          <div className="md-form mdForm_margin col-lg-6">
                            <input
                              type="text"
                              id={`orangeForm-name-${index}`}
                              className="form-control"
                              placeholder=" Last Name"
                              value={traveler.lname}
                              name="lname"
                              onChange={(e) => handleChange(e, index)}
                            />
                            {errors[index] && errors[index].lname && (
                              <p
                                className="style01"
                                style={{ fontSize: "14px" }}
                              >
                                {errors[index].lname}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="row ">
                          <div className="md-form mdForm_margin col-lg-6">
                            <input
                              type="text"
                              id={`orangeForm-name-${index}`}
                              className="form-control"
                              placeholder=" Email"
                              value={traveler.email}
                              name="email"
                              onChange={(e) => handleChange(e, index)}
                            />
                            {errors[index] && errors[index].email && (
                              <p
                                className="style01"
                                style={{ fontSize: "14px" }}
                              >
                                {errors[index].email}
                              </p>
                            )}
                          </div>
                          <div className="md-form mdForm_margin col-lg-6">
                            <input
                              type="text"
                              id={`orangeForm-name-${index}`}
                              className="form-control"
                              placeholder=" Contact No."
                              value={traveler.contactNo}
                              name="contactNo"
                              onChange={(e) => handleChange(e, index)}
                            />
                            {errors[index] && errors[index].contactNo && (
                              <p
                                className="style01"
                                style={{ fontSize: "14px" }}
                              >
                                {errors[index].contactNo}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="row datePick0">
                          <div className=" md-form mdForm_margin  col-lg-6 col-md-12">
                            <label className="doblable">DOB</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                key={traveler.id}
                                className="datetm4"
                                value={
                                  traveler.dob ? dayjs(traveler.dob) : null
                                }
                                onChange={(date) =>
                                  DobHandlechange(date, index)
                                }
                                format="DD/MM/YYYY"
                                renderInput={(params) => (
                                  <TextField {...params} placeholder="DOB" />
                                )}
                              />
                            </LocalizationProvider>
                            {errors[index] && errors[index].dob && (
                              <p
                                className="style01"
                                style={{ fontSize: "14px" }}
                              >
                                {errors[index].dob}
                              </p>
                            )}
                          </div>

                          <div className="expected_travelDate md-form mdForm_margin col-lg-6">
                            <label className="doblable">
                              Expected Travel Date
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                minDate={currentDate}
                                key={traveler.id}
                                className="datetm4"
                                value={
                                  traveler.expectedDate
                                    ? dayjs(traveler.expectedDate)
                                    : null
                                }
                                onChange={(date) =>
                                  ExpectedDateHandlechange(date, index)
                                }
                                format="DD/MM/YYYY"
                                renderInput={(params) => (
                                  <TextField {...params} placeholder="DOB" />
                                )}
                              />
                            </LocalizationProvider>
                            {errors[index] && errors[index].expectedDate && (
                              <p
                                className="style01"
                                style={{ fontSize: "14px" }}
                              >
                                {errors[index].expectedDate}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="row ">
                          <div className="md-form mdForm_margin col-lg-6">
                            <input
                              required
                              type="text"
                              id={`orangeForm-name-${index}`}
                              className="form-control"
                              placeholder=" Passport No."
                              value={traveler.passportNo}
                              name="passportNo"
                              onChange={(e) => handleChange(e, index)}
                            />
                            {errors[index] && errors[index].passportNo && (
                              <p
                                className="style01"
                                style={{ fontSize: "14px" }}
                              >
                                {errors[index].passportNo}
                              </p>
                            )}
                          </div>
                        </div>

                        <Form>
                          <Form.Group
                            className="  mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Details</Form.Label>
                            <Form.Control
                              className="textSrea_example"
                              as="textarea"
                              rows={3}
                              value={traveler.details}
                              name="details"
                              onChange={(e) => handleChange(e, index)}
                            />
                            {errors[index] && errors[index].details && (
                              <p
                                className="style01"
                                style={{ fontSize: "14px" }}
                              >
                                {errors[index].details}
                              </p>
                            )}
                          </Form.Group>
                        </Form>

                        {country.name === "India" ? (
                          <>
                            <div className="md-form mdForm_margin">
                              <input
                                style={{ borderRadius: "3px" }}
                                required
                                type="text"
                                className="form-control"
                                placeholder="Identification Mark"
                                value={traveler.IdMark}
                                name="IdMark"
                                onChange={(e) => handleChange(e, index)}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {/* </div> */}
                        {index === travelers.length - 1 && (
                          <div className=" addPerson01 ">
                            <button
                              type="button"
                              className="btn btn-outline-white waves-effect waves-light"
                              onClick={addPerson}
                            >
                              Add Person <IoMdAddCircle />
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-white waves-effect waves-light"
                              onClick={() => removePerson(index)}
                            >
                              Remove
                            </button>
                            <button
                              className="btn btn-outline-white waves-effect waves-light"
                              onClick={handleSubmitTraveler}
                            >
                              next
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card Amount_cardss">
                <div className="Suggest_animate">
                  <p className="scrolling-text">
                    Note:- Please make sure your travel dates are after visa
                    response or visa appointment dates.
                  </p>
                </div>
                <div className="card-content">
                  <h4>
                    Applicate Date:-{" "}
                    <span className="DestinatioCont">{selectedDate}</span>
                  </h4>
                  <h4>
                    Destination:-{" "}
                    {country?.name ? (
                      <span className="DestinatioCont">{country.name}</span>
                    ) : (
                      ""
                    )}
                  </h4>
                  <div className="price-I">
                    <h4>
                      Price:-{" "}
                      {country?.price ? (
                        <span className="DestinatioCont">
                          <BsCurrencyRupee />{" "}
                          {appointmentPrice
                            ? appointmentPrice
                            : multyEntryPrice
                            ? multyEntryPrice
                            : country?.price}{" "}
                          \ Per Person
                        </span>
                      ) : (
                        ""
                      )}
                      <span>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography
                                color="inherit"
                                className="Breckage"
                                style={{ fontWeight: "600" }}
                              >
                                Price Breakage:-
                              </Typography>
                              <h5
                                className="BrackageCharge"
                                style={{
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  color: "green",
                                }}
                              >
                                {" "}
                                {country?.PriceBreakage?.para
                                  ? country?.PriceBreakage?.para
                                  : ""}
                              </h5>
                              <h5
                                className="BrackageCharge"
                                style={{ fontWeight: "500" }}
                              >
                                Authority Fees:-{" "}
                                <span style={{ color: "red" }}>
                                  {" "}
                                  {country?.PriceBreakage?.AFees
                                    ? country?.PriceBreakage?.AFees
                                    : ""}
                                </span>
                              </h5>
                              <h5
                                className="BrackageCharge"
                                style={{ fontWeight: "500" }}
                              >
                                Service Fees:-{" "}
                                <span style={{ color: "red" }}>
                                  {" "}
                                  {country?.PriceBreakage?.SCharge
                                    ? country?.PriceBreakage?.SCharge
                                    : ""}
                                </span>
                              </h5>
                              <h5 className="h5heading">
                                All the Service charges would be refundable in
                                case of not delivering the visa on time.
                              </h5>
                            </React.Fragment>
                          }
                        >
                          <Button>
                            <RiInformation2Line />
                          </Button>
                        </HtmlTooltip>
                      </span>
                    </h4>
                  </div>
                  <h4>
                    {appointmentPrice ? usaAppointment : displayText}:-{" "}
                    <span className="DestinatioCont">
                      {appointmentPrice
                        ? country.date
                        : USresponseDate && USresponseDate !== "Invalid date"
                        ? USresponseDate
                        : responseDate}
                    </span>
                  </h4>
                </div>
              </div>
              {/* {country.name === "UAE" ? (
                <div className="checkbox">
                  <Form.Check
                    aria-label="option 1"
                    label="60 Days Discounted Price of UAE"
                    checked={check}
                    onClick={handleCheckbox}
                  />
                </div>
              ) : (
                ""
              )} */}
              <div className="cuponCode">
                <h4>Enter Your Coupon Code</h4>
                <input
                  type="text"
                  value={cCode}
                  onChange={handleCouponCodeChange}
                  placeholder="Enter Coupon Code"
                />
                <button onClick={applyCouponCode}>Apply</button>
                <button
                  onClick={removeCouponCode}
                  style={{ backgroundColor: "blue" }}
                >
                  Remove
                </button>
                {notify && (
                  <p style={{ color: "green", fontWeight: "500" }}>{notify}</p>
                )}
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                {discountedPrice ? (
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "1rem",
                      marginTop: "14px",
                    }}
                  >
                    Updated Price{" "}
                    <span className="udatedPrice">
                      <BsCurrencyRupee style={{ marginBottom: "1px" }} />{" "}
                      {discountedPrice}
                    </span>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TravelerDetails;
