

//  Register form validation******/////

export const validateFormRegister = (RegformData) => {
    const errors = {};
  
    // name
    if (!RegformData?.name?.trim()) {
      errors.name = 'Name is required *';
    }
    if (!RegformData?.Cname?.trim()) {
      errors.Cname = 'Company Name is required *';
    }  
    //Last name
    if (!RegformData?.lname?.trim()) {
      errors.lname = 'Last Name is required *';
    }
    // Moblie No
    if (!RegformData.Cnomber) {
      errors.Cnomber = "Contact number is required";
    }else if (!validatePhoneNumber(RegformData.Cnomber)) {
      errors.Cnomber ='Please enter digits only*'
    }else if (!validatePhoneNumbers(RegformData.Cnomber)){
      errors.Cnomber = "10-digit numeric phone number"
    } 

      //city name
      if (!RegformData?.city?.trim()) {
        errors.city = 'City is required *';
      }
        //state name
    if (!RegformData?.state?.trim()) {
      errors.state = 'State is required *';
    }
  
    //  email
    if (!RegformData?.email?.trim()) {
      errors.email = 'Email is require *';
    } else if (!isValidEmail(RegformData.email)) {
      errors.email = 'Invalid email address *';
    }
  
    //  password
    if (!RegformData.password || !validatePassword(RegformData.password)) {
      errors.password = 'Fill minimum 6 characters  *';
    }   
    return errors;
  };
 // Validate Email 💯
 const isValidEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};
// Validate password 💯
export const validatePassword = (password) => {
  const hasAlphanumeric = /[a-zA-Z0-9]/.test(password);
  return password.length >= 6 && hasAlphanumeric; 
};

  //  Login form validation******/////

export const validateFormLogin = (loginData) => {
    const errors = {};
  
    // Validate email
    if (!loginData?.email?.trim()) {
      errors.email = 'Email is required *';
    } else if (!isValidEmail(loginData.email)) {
      errors.email = 'Invalid email address *';
    }
    // Validate password
    if (!loginData.password || !validatePassword(loginData.password)) {
      errors.password = 'Fill minimum 6 characters *';
    }
    return errors;
  };


// Traveller validations 💯 👍
  export const validTrevellers = (travelers) => {
    const errors = travelers.map((person) => {
      const personErrors = {};
  
      if (!person.name) {
        personErrors.name = "First name is required";
      }
  
      if (!person.lname) {
        personErrors.lname = "Last name is required";
      }
      if (!person.details) {
        personErrors.details = "Details required";
      }
  
      if (!person.email) {
        personErrors.email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(person.email)) {
        personErrors.email = "Email address is invalid";
      }
  
      if (!person.contactNo) {
        personErrors.contactNo = "Contact number is required";
      }else if (!validatePhoneNumber(person.contactNo)) {
        personErrors.contactNo ='Please enter digits only*'
      }else if (!validatePhoneNumbers(person.contactNo)){
        personErrors.contactNo = "10-digit numeric phone number"
      } 
  
      if (!person.dob) {
        personErrors.dob = "Date of birth is required";
      }  
      if (!person.expectedDate) {
        personErrors.expectedDate = "Expected Traveller Date is required";
      }  
      if (!person.passportNo) {
        personErrors.passportNo = "Passport number is required";
      }
  
      return personErrors;
    });
  
    return errors;
  };    
      

      export const validatePhoneNumbers = (phone) => {
        const phonePattern = /^\d{10}$/; // Simple example: 10-digit numeric phone number
        return phonePattern.test(phone);
      };
      
      export const validatePhoneNumber = (phone) => {
        const phonePattern = /^[0-9]+$/; // Regular expression to allow only numbers  
        return phonePattern.test(phone);
      };

      
  //  ****************validateFormFreeLancer form validation**************

export const validateFormFreeLancer = (data) => {
  const formErrors = {};

  if(!data.name.trim()){
    formErrors.name = 'Name is required*'
  }
  if(!data.City.trim()){
    formErrors.City = 'Address is required*'
  }
  if(!data.Cnomber.trim()){
    formErrors.Cnomber = 'Mobile No. is required*'
  }else if(!validatePhoneNumber(data.Cnomber)){
    formErrors.Cnomber = 'Please enter digits only*'
  }else if(!validatePhoneNumbers(data.Cnomber)){
    formErrors.Cnomber = '10-digit numeric phone number*'
  }

  // Validate email
  if (!data?.email?.trim()) {
    formErrors.email = 'Email is required *';
  } else if (!isValidEmail(data.email)) {
    formErrors.email = 'Invalid email address *';
  }

  return formErrors;
};
  

// validation For Applicant ************************************* 💯 👍
export const validationForApplicant = (workvisa) => {
  const errors = workvisa.map((person) => {
    const personErrors = {};

    if (!person.name) {
      personErrors.name = "First name is required";
    }

    if (!person.lname) {
      personErrors.lname = "Last name is required";
    }
    if (!person.email) {
      personErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(person.email)) {
      personErrors.email = "Email address is invalid";
    }

    if (!person.MobileNO) {
      personErrors.MobileNO = "Contact number is required";
    }else if (!validatePhoneNumber(person.MobileNO)) {
      personErrors.MobileNO ='Please enter digits only*'
    }else if (!validatePhoneNumbers(person.MobileNO)){
      personErrors.MobileNO = "10-digit numeric phone number"
    } 

    if (!person.dob) {
      personErrors.dob = "Date of birth is required";
    } 
    if (!person.passportNo) {
      personErrors.passportNo = "Passport number is required";
    }

    return personErrors;
  });

  return errors;
}; 