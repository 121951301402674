import React from "react";
import "../Footer/footer.css";
import { Link, NavLink } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { BiLogoTwitter } from "react-icons/bi";
import { AiFillInstagram } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa6";
import { IoLocation } from "react-icons/io5";

const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="footerRight">
                <p>
                  Welcome to Vijatour.com, your trusted partner for fast,
                  reliable, and affordable Visa services. Our mission is to make
                  your travel dreams come true by ensuring a hassle-free Visa
                  application process, guaranteeing timely responses, and
                  providing complete transparency throughout.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="part1">
                <div className="slogan">
                  <h4>COMPANY</h4>
                </div>
                <ul>
                  <li>
                    <Link to={"/About-Us"}>About Us</Link>
                  </li>
                  <li>
                    <Link to={"/Traveller-Blogs"}>Traveller Blogs</Link>
                  </li>
                  <li>
                    <Link to={"/Privacy-Policy"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={"/Refund-Policy"}>Refund Policy</Link>
                  </li>
                  <li>
                    <Link to={"/Term-And-Conditions"}>
                      Terms and Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to={"/otherCountris"}>Other Countries</Link>
                  </li>
                  <li>
                    <Link to={"/Freelancer"}>Freelancer</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="part1">
                <div className="slogan">
                  <h4>CONTACT US</h4>
                </div>
                <ul className="icons">
                  <li style={{ padding: "0px" }}>
                    <NavLink>
                      <MdEmail /> support@vijatour.com
                    </NavLink>
                  </li>
                  <li style={{ padding: "0px" }}>
                    <NavLink>
                      <BsTelephoneFill /> (+91) 8685081010 {" "}
                      <span style={{ color: "yellow" }}>INDIA</span>
                    </NavLink>
                  </li>
                  <li style={{ padding: "0px" }}>
                    <NavLink>
                      <BsTelephoneFill /> (+61) 0435149195{" "}
                      <span style={{ color: "yellow" }}>AUSTRALIA</span>
                    </NavLink>
                  </li>
                  <li style={{ padding: "0px" }}>
                    <h5 style={{ color: "yellow" }}>
                      <IoLocation style={{ color: "white" }} /> INDIA
                    </h5>
                    <p>
                      {" "}
                      First Floor,Besides Sadar Thana,Delhi Rohtak Corridor,opp
                      metro pillar no 830, Bahadurgarh,Haryana -124507
                    </p>
                  </li>
                  <li style={{ padding: "0px" }}>
                    <h5 style={{ color: "yellow" }}>
                      <IoLocation style={{ color: "white" }} /> AUSTRALIA
                    </h5>
                    <p style={{ lineHeight: "2", margin: "0px" }}>
                      {" "}
                      10 Carter Street, Lidcombe NSW 2141
                    </p>
                  </li>
                </ul>
              </div>
              <ul className="icon01">
                <li>
                  <Link>
                    <BiLogoFacebookCircle />
                  </Link>
                </li>
                <li>
                  <Link>
                    <BiLogoTwitter />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"https://www.instagram.com/vijatour"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillInstagram />
                  </Link>
                </li>
                <li>
                  <Link>
                    <BsYoutube />
                  </Link>
                </li>
              </ul>
            </div>
            {/* </div>
            </div> */}
          </div>
          <div className="copyright">
            <p>Copyright 2024 Vijatour</p>
          </div>
          <Link
            className="backToTop"
            to={"https://wa.me/918950546462?text=How%20can%20I%20help%20you%3F"}
            target="blank"
          >
            <FaWhatsapp />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
