import React, { useState, useRef, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./document.css";
// Strper
import Stepper from "@mui/joy/Stepper";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { CiCircleRemove } from "react-icons/ci";
import VisaContext from "../../../context/visa-context";
import Header from "../../Home/Header";
import MyLoader from "../../Loader/MyLoader";
import { Helmet } from "react-helmet";
import { BsCurrencyRupee } from "react-icons/bs";
import Accordion from "react-bootstrap/Accordion";
import Button from "@mui/material/Button";
import axios from "axios";

const Document = () => {
  const [photos, setPhotos] = useState([]); // Initialize as an array
  const [passport, setPassport] = useState([]);
  const [otherDoc, setOtherDoc] = useState([]);
  // addons state management 💯 💯
  const [dummyPrice, setDummyPrice] = useState(499);
  const [fDummyPrice, setFDummyPrice] = useState(999);
  const [isOneWay, setIsOneWay] = useState(true); // One-Way is selected by default
  const [countryPrice, setCountryPrice] = useState(0);
  const [addOnPrices, setAddOnPrices] = useState({
    flight: 0,
    hotel: 0,
    itinerary: 0,
    coverLetter: 0,
  });
  const [addedAddOns, setAddedAddOns] = useState({
    flight: false,
    hotel: false,
    itinerary: false,
    coverLetter: false,
  });
  const [couponCode, setCouponCode] = useState("");
  const [updatedAmount, setUpdatedAmount] = useState(null);
  const [message, setMessage] = useState("");
  const [activeAddOns, setActiveAddOns] = useState([]);

  const photoInputRefs = useRef([]);
  const passportInputRefs = useRef([]);
  const otherDocInputRefs = useRef([]);

  const location = useLocation();
  const { state } = location;
  const { travelers, discountedPrice, country, futureDate, selectedDate } =
    state || {};
  const ctx = useContext(VisaContext);
  const navigate = useNavigate();
  useEffect(() => {
    const traveller = localStorage.getItem("traveller");
    const t = JSON.parse(traveller);
    if (t) {
      ctx.setTravellerData(t);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // handle phot passport other document function define here
  const handlePhotoChange = (index, e) => {
    const file = e.target.files[0];
    setPhotos((prevPhotos) => {
      const newPhotos = [...prevPhotos];
      newPhotos[index] = file;
      return newPhotos;
    });
  };

  const handlePassportChange = (index, e) => {
    const file = e.target.files[0];
    setPassport((prevPassport) => {
      const newPassport = [...prevPassport];
      newPassport[index] = file;
      return newPassport;
    });
  };

  const handleOtherDocChange = (index, e) => {
    const file = e.target.files[0];
    setOtherDoc((prevOtherDoc) => {
      const newOtherDoc = [...prevOtherDoc];
      newOtherDoc[index] = file;
      return newOtherDoc;
    });
  };

  const handlePhotoUpload = async (index, e) => {
    e.preventDefault();
    ctx.setLoading(true);
    const selectedPhoto = photos[index];

    if (!selectedPhoto) {
      alert("Please select a photo to upload.");
      ctx.setLoading(false);
      return;
    }
    const fd = new FormData();
    fd.append("file", selectedPhoto);
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/upload/photo",
        {
          method: "post",
          body: fd,
        }
      );
      const dd = await response.json();
      if (dd.success) {
        alert("Photo uploaded successfully!!!");
      }
      if (dd.fileName && dd.originalname) {
        ctx.setLoading(false);
        ctx.photoUpdater({
          filename: dd.fileName,
          originalname: dd.originalname,
          id: index,
        });
        if (photoInputRefs.current[index]) {
          photoInputRefs.current[index].value = "";
        }
      }
    } catch (error) {
      ctx.setLoading(false);
    }
  };

  const handleUploadPassport = async (index, e) => {
    e.preventDefault();
    ctx.setLoading(true);
    const selectedPassport = passport[index];
    if (!selectedPassport) {
      alert("Please select a passport to upload.");
      ctx.setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file2", selectedPassport);
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/upload/passport",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (data.success) {
        ctx.setLoading(false);
        alert("Passport uploaded successfully!!!");
        if (passportInputRefs.current[index]) {
          passportInputRefs.current[index].value = "";
        }
        setPassport((prevPassport) => {
          const newPassport = [...prevPassport];
          newPassport[index] = null;
          return newPassport;
        });
        if (data.fileName && data.originalname) {
          ctx.passportUpdater({
            filename: data.fileName,
            originalname: data.originalname,
            id: index,
          });
        }
      }
    } catch (error) {
      ctx.setLoading(false);
    }
  };

  const handleUploadOtherDoc = async (index, e) => {
    e.preventDefault();
    ctx.setLoading(true);
    const selectedOtherDoc = otherDoc[index];
    if (!selectedOtherDoc) {
      alert("Please select a document to upload.");
      ctx.setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file3", selectedOtherDoc);
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/upload/otherDoc",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (data.success) {
        ctx.setLoading(false);
        alert("Document uploaded successfully!!!");
        if (otherDocInputRefs.current[index]) {
          otherDocInputRefs.current[index].value = "";
        }
        setOtherDoc((prevOtherDoc) => {
          const newOtherDoc = [...prevOtherDoc];
          newOtherDoc[index] = null;
          return newOtherDoc;
        });
        if (data.fileName && data.originalname) {
          ctx.OtherUpdater({
            filename: data.fileName,
            originalname: data.originalname,
            id: index,
          });
        }
      }
    } catch (error) {
      ctx.setLoading(false);
    }
  };

  // upload filename and info

  const handleSaveAllFileNameMongo = async (e) => {
    e.preventDefault();
    ctx.setLoading(true);

    let newDataArray = ctx.travellerData.map((obj) => {
      // Create a shallow copy of the object
      let newObj = { ...obj };

      // Handle `photo` array to ensure no duplication
      if (Array.isArray(newObj.photo)) {
        newObj.photo = newObj.photo.map((file) => ({ ...file }));
      }
      // Handle `passport` array
      if (Array.isArray(newObj.passport)) {
        newObj.passport = newObj.passport.map((file) => ({ ...file }));
      }

      // Handle `otherDocuments` array
      if (Array.isArray(newObj.otherDocuments)) {
        newObj.otherDocuments = newObj.otherDocuments.map((file) => ({
          ...file,
        }));
      }

      return newObj;
    });

    const newData = JSON.stringify(newDataArray);
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/submit-application",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: newData,
        }
      );

      if (response.ok) {
        ctx.setLoading(false);
        const data = await response.json();
        navigate("/Checkout", {
          state: {
            travelers,
            updatedAmount,
            countryPrice,
            addOnPrices,
            country,
            futureDate,
            selectedDate,
          },
        });
      } else {
        ctx.setLoading(false);
        const errorData = await response.json();
      }
    } catch (error) {
      ctx.setLoading(false);
    }
  };
  // handle Delete Api

  const handleDeletePassport = async (nameToDelete) => {
    ctx.setLoading(true);
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/delete/passport",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ fileName: nameToDelete }),
        }
      );
      const data = await response.json();
      if (data.success) {
        ctx.setLoading(false);
        ctx.handlePassportDelete(nameToDelete);
        alert("Deleted");
      } else {
        ctx.setLoading(false);
        alert("Failed to delete the passport from the database.");
      }
    } catch (error) {
      ctx.setLoading(false);
    }
  };
  const handlerDeletePhoto = async (nameToDelete) => {
    ctx.setLoading(true);
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/delete/passport",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ fileName: nameToDelete }),
        }
      );
      const data = await response.json();
      if (data.success) {
        ctx.setLoading(false);
        ctx.handlePhotoDelete(nameToDelete);
        alert("Deleted");
      } else {
        ctx.setLoading(false);
        alert("Failed to delete the passport from the database.");
      }
    } catch (error) {
      ctx.setLoading(false);
    }
  };
  const handlerOtherDelete = async (nameToDelete) => {
    ctx.setLoading(true);
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/delete/passport",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ fileName: nameToDelete }),
        }
      );
      const data = await response.json();
      if (data.success) {
        ctx.setLoading(false);
        ctx.handleOtherDelete(nameToDelete);
        alert("Deleted");
      } else {
        ctx.setLoading(false);
        alert("Failed to delete the passport from the database.");
      }
    } catch (error) {
      ctx.setLoading(false);
    }
  };

  // addons logics and functions explain here 💯

  useEffect(() => {
    if (discountedPrice) {
      const initialPrice = Number(discountedPrice) * travelers?.length;
      setCountryPrice(initialPrice);
    }
  }, [discountedPrice, travelers?.length]);

  const handleAddButtonClick = (addOnType, amount, event) => {
    event.preventDefault();
    const priceToAdd = travelers?.length * amount;
    setCountryPrice((prevPrice) => prevPrice + priceToAdd);
    setAddOnPrices((prevState) => ({
      ...prevState,
      [addOnType]: prevState[addOnType] + priceToAdd,
    }));
    setAddedAddOns((prevState) => ({ ...prevState, [addOnType]: true }));
    setActiveAddOns((prevState) => [...prevState, addOnType]);
  };

  const handleRemoveButtonClick = (addOnType, amount, event) => {
    event.preventDefault();
    const priceToSubtract = travelers?.length * amount;
    setCountryPrice((prevPrice) => prevPrice - priceToSubtract);
    setAddOnPrices((prevState) => ({
      ...prevState,
      [addOnType]: prevState[addOnType] - priceToSubtract,
    }));
    setAddedAddOns((prevState) => ({ ...prevState, [addOnType]: false }));
    setActiveAddOns((prevState) =>
      prevState.filter((addOn) => addOn !== addOnType)
    );
  };

  // ***************other Document selct****************** 💯
  const handleDocumentChange = (index, event) => {
    const selectedDocument = event.target.value;
  };
  // ***************refund Coupan Code*********************** 💯
  const userId = localStorage.getItem("userId");
  const handleApplyCoupon = async () => {
    if (!userId) {
      setMessage("User not logged in or registered");
      return;
    }

    try {
      const response = await axios.post(
        "https://backend-visa2.vercel.app/api/apply-coupon",
        {
          userId: userId,
          couponCode: couponCode,
        }
      );

      // Update the price on the frontend if coupon is applied successfully
      if (response.status === 200) {
        const newAmount = parseInt(countryPrice) - 299; // Apply discount on the frontend only
        setUpdatedAmount(newAmount ? newAmount : countryPrice);
        setMessage("Coupon applied successfully!");
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        setMessage(error.response.data.message); // Display error message from backend
      }
    }
  };

  const handleOneWaySelect = () => {
    setFDummyPrice(999);
    setIsOneWay(true); // Set One-Way as selected
  };

  const handleTwoWaySelect = () => {
    if (!isOneWay) {
      // If already on Two-Way, switch back to One-Way
      handleOneWaySelect();
    } else {
      setFDummyPrice(1998); // Set price for Two-Way
      setIsOneWay(false); // Set Two-Way as selected
    }
  };

  const currentUrl = window.location.href;
  return (
    <>
      <div className="updatea_AddOns">
        <p className="show addOns"></p>
      </div>
      <div className="ViaApply_Header trevellerHead">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Document</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
        {ctx.isLoading && <MyLoader />}
      </div>
      <div className="container main_pageLogin">
        <div className="stepper0144">
          <Stepper size="sm">
            <Step
              indicator={
                <StepIndicator
                  variant="solid"
                  sx={{ backgroundColor: "red", color: "white" }}
                >
                  1
                </StepIndicator>
              }
            >
              Step 1
            </Step>
            <Step
              indicator={
                <StepIndicator
                  variant="solid"
                  sx={{ backgroundColor: "red", color: "white" }}
                >
                  2
                </StepIndicator>
              }
            >
              Step 2
            </Step>
            <Step indicator={<StepIndicator>3</StepIndicator>}>Step 3</Step>
          </Stepper>
        </div>

        <div className="document_required_accordian1">
          <Accordion className="document_required ">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h3>
                  Document for <span>{country?.name}</span>{" "}
                </h3>
              </Accordion.Header>
              <Accordion.Body>
                <div className="document_heading">
                  {country.docs.map((document, index) => {
                    // Start a new container for every group of three document headings
                    if (index % 3 === 0) {
                      return (
                        <div key={index} className="document-group">
                          {country.docs
                            .slice(index, index + 3)
                            .map((doc, subIndex) => (
                              <span key={subIndex} className="document-item">
                                {doc.heading}
                              </span>
                            ))}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {/* <div>
            <h4><span>Notice:-</span> </h4>
          </div> */}
        </div>

        <div className="loginForm11">
          <div className="row loginform_style documentFile11">
            {/* addons */}
            <div className="col-lg-4 col-md-12">
              <div className="FormMain">
                <form className="mainformAdd">
                  <h2>Add Ons</h2>
                  <p>
                    No. Of Travellers{" "}
                    <span style={{ color: "black", fontWeight: "600", marginLeft:"5px" }}>
                      {ctx.travellerData.length}{" "}
                    </span>
                  </p>
                  <div className="dummyfightaddonns">
                    <h5>
                      One-Way{" "}
                      <span>
                        <Form.Check
                          type="radio"
                          aria-label="radio"
                          checked={isOneWay}
                          onChange={handleOneWaySelect}
                        />
                      </span>
                    </h5>
                    <h5>
                      Two-Way{" "}
                      <span>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          checked={!isOneWay}
                          onChange={handleTwoWaySelect}
                        />
                      </span>
                    </h5>
                  </div>
                  <div className="DummyFlightTicket">
                    <h5 className="flight_h5">
                      Flight Ticket (Live PNR){" "}
                      <strong>
                        <BsCurrencyRupee /> {fDummyPrice * travelers?.length}
                      </strong>{" "}
                    </h5>
                    <button
                      onClick={(event) =>
                        handleAddButtonClick("flight", fDummyPrice, event)
                      }
                      disabled={addedAddOns.flight}
                      style={addedAddOns.flight ? { display: "none" } : {}}
                    >
                      Add
                    </button>
                    <button
                      onClick={(event) =>
                        handleRemoveButtonClick("flight", fDummyPrice, event)
                      }
                      disabled={!addedAddOns.flight}
                      style={
                        !addedAddOns.flight
                          ? { display: "none" }
                          : { backgroundColor: "blue", color: "white" }
                      }
                    >
                      Remove
                    </button>
                  </div>
                  <div className="DummyFlightTicket">
                    <h5>
                      Hotel Booking{" "}
                      <strong>
                        <BsCurrencyRupee /> {dummyPrice * travelers?.length}
                      </strong>{" "}
                    </h5>
                    <button
                      onClick={(event) =>
                        handleAddButtonClick("hotel", dummyPrice, event)
                      }
                      disabled={addedAddOns.hotel}
                      style={addedAddOns.hotel ? { display: "none" } : {}}
                    >
                      Add
                    </button>
                    <button
                      onClick={(event) =>
                        handleRemoveButtonClick("hotel", dummyPrice, event)
                      }
                      disabled={!addedAddOns.hotel}
                      style={
                        !addedAddOns.hotel
                          ? { display: "none" }
                          : { backgroundColor: "blue", color: "white" }
                      }
                    >
                      Remove
                    </button>
                  </div>
                  <div className="DummyFlightTicket">
                    <h5>
                      Itinerary{" "}
                      <strong>
                        <BsCurrencyRupee /> {dummyPrice * travelers?.length}
                      </strong>{" "}
                    </h5>
                    <button
                      onClick={(event) =>
                        handleAddButtonClick("itinerary", dummyPrice, event)
                      }
                      disabled={addedAddOns.itinerary}
                      style={addedAddOns.itinerary ? { display: "none" } : {}}
                    >
                      Add
                    </button>
                    <button
                      onClick={(event) =>
                        handleRemoveButtonClick("itinerary", dummyPrice, event)
                      }
                      disabled={!addedAddOns.itinerary}
                      style={
                        !addedAddOns.itinerary
                          ? { display: "none" }
                          : { backgroundColor: "blue", color: "white" }
                      }
                    >
                      Remove
                    </button>
                  </div>
                  <div className="DummyFlightTicket">
                    <h5>
                      Cover Letter{" "}
                      <strong>
                        <BsCurrencyRupee /> {dummyPrice * travelers?.length}
                      </strong>{" "}
                    </h5>
                    <button
                      onClick={(event) =>
                        handleAddButtonClick("coverLetter", dummyPrice, event)
                      }
                      disabled={addedAddOns.coverLetter}
                      style={addedAddOns.coverLetter ? { display: "none" } : {}}
                    >
                      Add
                    </button>
                    <button
                      onClick={(event) =>
                        handleRemoveButtonClick(
                          "coverLetter",
                          dummyPrice,
                          event
                        )
                      }
                      disabled={!addedAddOns.coverLetter}
                      style={
                        !addedAddOns.coverLetter
                          ? { display: "none" }
                          : { backgroundColor: "blue", color: "white" }
                      }
                    >
                      Remove
                    </button>
                  </div>
                </form>
              </div>
              <div className="refund_coupan_code">
                <h2>Registration Coupon Code</h2>
                <p style={{ color: "green" }}>
                  <strong
                    style={{
                      color: "red",
                      fontSize: "16px",
                      fontWeight: "500",
                      marginRight: "3px",
                    }}
                  >
                    Note:
                  </strong>
                  Please redeem your registeration coupon code here, it can be
                  only redeemed once.{" "}
                </p>
                <div className="Apply_coupan_input">
                  <input
                    type="text"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    placeholder="Enter coupon code"
                  />
                  <Button
                    onClick={handleApplyCoupon}
                    variant="contained"
                    size="small"
                  >
                    Apply
                  </Button>
                </div>
                {message && (
                  <p
                    style={{
                      color: "red",
                      fontWeight: "500",
                      marginTop: "5px",
                    }}
                  >
                    {message}
                  </p>
                )}
                {updatedAmount !== null && (
                  <p
                    style={{
                      color: "black",
                      fontWeight: "500",
                      marginTop: "8px",
                    }}
                  >
                    Update Amount: ₹{" "}
                    <span className="final_ammount_style">{updatedAmount}</span>
                  </p>
                )}
              </div>
            </div>
            {/* ******************upload document******************* */}
            <div className="card col-lg-8 col-md-12 col-sm-12 ripe-malinka-gradient form-white upload_File_Form11">
              <form onSubmit={handleSaveAllFileNameMongo}>
                <div className="card-body">
                  {ctx.travellerData.map((data, index) => (
                    <div key={index}>
                      <h2 className="text-center font-up font-bold py-2 travelHead">
                        Upload Documents
                      </h2>
                      <div className="travellarppp">
                        <p className="stlyleTravellerCount">
                          Traveller{" "}
                          <span>
                            <h4>{index + 1}</h4>
                          </span>
                        </p>
                      </div>
                      <div className="row">
                        <div className="md-form mdForm_margin col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            value={data.name}
                            disabled
                          />
                        </div>
                        <div className="md-form mdForm_margin col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            value={data.lname}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="md-form mdForm_margin col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            value={data.email}
                            disabled
                          />
                        </div>
                        <div className="md-form mdForm_margin col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Passport No."
                            value={data.contactNo}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="passportName photoName">
                        <div className="uplodFile">
                          <Form.Group
                            as={Col}
                            controlId={`formFile-passport-${index}`}
                            className="col_ChoosePhoto"
                          >
                            <Form.Label className="lableOfLabele">
                              Passport
                            </Form.Label>
                            <Form.Control
                              onChange={(e) => handlePassportChange(index, e)}
                              name="file"
                              type="file"
                              size="lg"
                              accept="application/pdf"
                              className="uplodFileINput"
                              ref={(el) =>
                                (passportInputRefs.current[index] = el)
                              }
                            />
                          </Form.Group>
                          <button
                            onClick={(e) => handleUploadPassport(index, e)}
                            type="button"
                            className="btn btn-outline-white waves-effect waves-light"
                          >
                            Upload
                          </button>
                        </div>
                        <div className="getPhoto">
                          {data.passport.length > 0 && (
                            <>
                              {data.passport.map((name, ind) => (
                                <p className="photoName_ooo" key={ind}>
                                  {name.filename}{" "}
                                  <CiCircleRemove
                                    key={ind}
                                    onClick={() =>
                                      handleDeletePassport(name.originalname)
                                    }
                                  />
                                </p>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="photoName">
                        <div className="uplodFile">
                          <Form.Group
                            as={Col}
                            controlId={`formFile-photo-${index}`}
                            className="col_ChoosePhoto"
                          >
                            <Form.Label className="lableOfLabele">
                              Photo
                            </Form.Label>
                            <Form.Control
                              onChange={(e) => handlePhotoChange(index, e)}
                              name="file"
                              type="file"
                              size="lg"
                              accept="image/png, image/jpeg"
                              ref={(el) => (photoInputRefs.current[index] = el)}
                            />
                          </Form.Group>
                          <button
                            type="button"
                            className="btn btn-outline-white waves-effect waves-light"
                            onClick={(e) => handlePhotoUpload(index, e)}
                          >
                            Upload
                          </button>
                        </div>
                        <div className="getPhoto">
                          {data.photo.length > 0 && (
                            <>
                              {data.photo.map((name, ind) => (
                                <p className="photoName_ooo" key={ind}>
                                  {name.filename}{" "}
                                  <CiCircleRemove
                                    key={ind}
                                    onClick={() =>
                                      handlerDeletePhoto(name.originalname)
                                    }
                                  />
                                </p>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="photoName">
                        <Col className="uplodFile buttonmanyDoc mb-3">
                          <Form.Group
                            as={Col}
                            className="col_ChoosePhoto"
                            controlId={`formFile-other-${index}`}
                          >
                            <Form.Label className="lableOfLabele handleSelecter_heading">
                              <select
                                className="form-select"
                                onChange={(e) => handleDocumentChange(index, e)}
                              >
                                <option value="">Other Document</option>
                                {country.docs
                                  .filter(
                                    (document) =>
                                      document.heading !== "Photo" &&
                                      document.heading !== "Passport"
                                  )
                                  .map((document, docIndex) => (
                                    <option
                                      key={docIndex}
                                      value={document.heading}
                                      className="DocHeading"
                                    >
                                      {document.heading}
                                    </option>
                                  ))}
                              </select>
                            </Form.Label>

                            <Form.Control
                              ref={(el) =>
                                (otherDocInputRefs.current[index] = el)
                              }
                              onChange={(e) => handleOtherDocChange(index, e)}
                              name="file"
                              type="file"
                              size="lg"
                              accept="application/pdf"
                            />
                          </Form.Group>
                          <button
                            type="button"
                            className="btn  waves-effect waves-light wave_light1"
                            onClick={(e) => handleUploadOtherDoc(index, e)}
                          >
                            Upload
                          </button>
                        </Col>
                        <div className="getPhoto">
                          {data.otherDocuments.length > 0 && (
                            <>
                              {data.otherDocuments.map((name, ind) => (
                                <p className="photoName_ooo" key={ind}>
                                  {name.filename}{" "}
                                  <CiCircleRemove
                                    key={ind}
                                    onClick={() =>
                                      handlerOtherDelete(name.originalname)
                                    }
                                  />
                                </p>
                              ))}
                            </>
                          )}
                        </div>
                        <p className="show addOns">
                          {activeAddOns.map((addOn) => (
                            <span key={addOn}>
                              {addOn.charAt(0).toUpperCase() + addOn.slice(1)}{" "}
                              added ,{" "}
                            </span>
                          ))}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="fullSubmit">
                  <button
                    type="submit"
                    className="btn btn-outline-white waves-effect waves-light importantBtn"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Document;
