import React from "react";
import "./nopage.css";
import { Link } from "react-router-dom";

const NoPage = () => {
  return (
    <>
      <div className="no">
        <div className="nopage">
          <h3>
            {" "}
            Please <Link  to={'/login'}> Login</Link> to continue on the webiste.
          </h3>
        </div>
      </div>
    </>
  );
};

export default NoPage;
