import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./addons.css";

const AddOnsPaymentSuccess = () => {
  const [regformData, setRegformData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    // Retrieve data from sessionStorage based on saved payment type
    const flightData = sessionStorage.getItem("FlightData");
    const hotelData = sessionStorage.getItem("HotelData");
    const coverLetterData = sessionStorage.getItem("CoverLetterData");
    const itineraryData = sessionStorage.getItem("ItineraryData");

    // Set regformData based on available data
    if (flightData) setRegformData(JSON.parse(flightData));
    else if (hotelData) setRegformData(JSON.parse(hotelData));
    else if (coverLetterData) setRegformData(JSON.parse(coverLetterData));
    else if (itineraryData) setRegformData(JSON.parse(itineraryData));   
  }, []);

  // Trigger API call if regformData is valid
  useEffect(() => {
    // if (isFormDataValid(regformData)) {
    submitDataBasedOnType(regformData);
    // }
  }, [regformData]);

  // Submit data based on type
  const submitDataBasedOnType = (data) => {
    if (data.AoType === "Flight Ticket") {
      handleFlighSubmit(data);
    } else if (data.AoType === "Hotel") {
      handleHotelSubmit(data);
    } else if (data.AoType === "Cover Letter") {
      coverLetterHandleSubmit(data);
    } else if (data.AoType === "Itinerary") {
      itineraryHandleSubmit(data);
    }
  };

  // Submit functions with data passed as arguments
  const handleFlighSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://backend-visa2.vercel.app/api/addOns/flight-booking",
        data
      );
      alert("Flight Ticket apply successfully");
      sessionStorage.removeItem("FlightData");
      sessionStorage.removeItem("userName");
      setTimeout(() => {
        navigate("/addonns");
      }, 5);
    } catch (error) {
      console.error("Failed to book flight", error);
    }
  };

  const handleHotelSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://backend-visa2.vercel.app/api/addOns/hotel-booking",
        data
      );
      alert("Hotel Ticket Apply successfully");
      sessionStorage.removeItem("HotelData");
      sessionStorage.removeItem("userName");
      setTimeout(() => {
        navigate("/addonns");
      }, 5);
    } catch (error) {
      console.error("Failed to book Hotel", error);
    }
  };

  const coverLetterHandleSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://backend-visa2.vercel.app/api/addOns/coverLetter-booking",
        data
      );
      alert("Cover Lettter Apply successfully");
      sessionStorage.removeItem("CoverLetterData");
      sessionStorage.removeItem("userName");
      setTimeout(() => {
        navigate("/addonns");
      }, 5);
    } catch (error) {
      console.error("Failed to book Cover Letter", error);
    }
  };

  const itineraryHandleSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://backend-visa2.vercel.app/api/addOns/itinerary-booking",
        data
      );
      alert("Itinerary Apply successfully");
      sessionStorage.removeItem("ItineraryData");
      sessionStorage.removeItem("userName");
      setTimeout(() => {
        navigate("/addonns");
      }, 5);
    } catch (error) {
      console.error("Failed to book Itinerary", error);
    }
  };

  return (
    <>
      <div className="center-container">
        <h2 className="success-text">Payment Successful</h2>
      </div>
    </>
  );
};

export default AddOnsPaymentSuccess;
