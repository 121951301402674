import React, { useEffect, useState } from "react";
import "./StartApplication.css";
import Header from "../../../../../Home/Header";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { WorkPermitCountries } from "../WorkPermitDatabase";

const StartApplication = () => {
  const [country, setCountry] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const selectedCountry = WorkPermitCountries.find(
      (country) => country.id === parseFloat(id)
    );
    setCountry(selectedCountry);
    // Scroll to the top of the page when the component mounts
  }, [id]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Add conditional rendering to prevent errors
  if (!country) {
    return <div>Loading...</div>; // Or a loading spinner
  }
  const handleNvigate = () => {
    navigate("/ApplicantDetails", { state: { country } });
  };

  const currentUrl = window.location.href;
  return (
    <>
      <div className="ViaApply_Header trevellerHead">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Start Application</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>
      <div className="mainwrapper_startApplication">
        <div className="container">
          <div className="row">
            {country.jobCards.map((jobData) => (
              <div className="col-lg-6">
                <div className="details_for_visaApply" key={jobData.id}>
                  <div className="card">
                    <div className="main_WorkCountry_name">
                      <h2>{jobData.Country} </h2>
                    </div>
                    <h3><span>Position: </span> {jobData.Job} </h3>
                    <div className="other_deatils">
                      <ul>
                        <li>
                          <strong>Salary:</strong>{" "}
                          <span> {jobData.Salary}</span>
                        </li>
                        {jobData.Working && (
                          <li>
                            <strong>Working:</strong>{" "}
                            <span>{jobData.Working} </span>
                          </li>
                        )}
                        <li>
                          <strong>Accommodation:</strong>{" "}
                          <span>{jobData.Accomodation}</span>
                        </li>
                        {jobData.Food && (
                          <li>
                            <strong>Food:</strong> <span>{jobData.Food}</span>
                          </li>
                        )}
                       
                        {jobData.skill && (
                          <li>
                          <strong>Skill:</strong>{" "}
                          <span>{jobData.skill}</span>
                        </li>
                        )}
                        {jobData.Insurance && (
                          <li>
                            <strong>Insurance:</strong>{" "}
                            <span>{jobData.Insurance}</span>
                          </li>
                        )}

                        <li>
                          <strong>Visa Fee:</strong>{" "}
                          <span>{jobData.VisaFee}</span>
                        </li>
                        <li>
                          <strong>Flight Ticket:</strong>{" "}
                          <span>{jobData.FlightT}</span>
                        </li>
                        <li>
                          <strong>Processing Time:</strong>{" "}
                          <span>{jobData.PTime}</span>
                        </li>
                        <li>
                          <strong>Service Charge:</strong>{" "}
                          <span> &#8377; {jobData.SC}</span>
                        </li>                       
                        <li>
                          <strong>Interview:</strong>{" "}
                          <span> {jobData.Interview}</span>
                        </li>
                        <li>
                          <strong>Contract:</strong>{" "}
                          <span> {jobData.Contract}</span>
                        </li>
                        {jobData.Document && (
                          <li>
                            <strong>Document Required:</strong>{" "}
                            <span> {jobData.Document}</span>
                          </li>
                        )}
                        {jobData.Experience && (
                          <li>
                            <strong>Experience: </strong>{" "}
                            <span> {jobData.Experience}</span>
                          </li>
                        )}
                      </ul>

                      {jobData.JD && (
                        <>
                          <h4
                            style={{
                              textAlign: "center",
                              fontSize: "18px",
                              fontWeight: "600",
                              color: "cornflowerblue",
                            }}
                          >
                            Job Description
                          </h4>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: jobData.JD,
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div className="workVisa_startBtn">
                      <button
                        onClick={handleNvigate}
                        className="btn btn-primary btn_primarybtn"
                      >
                        Start Application
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default StartApplication;
