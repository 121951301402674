import React, { useContext, useEffect } from "react";
import "../../userDashboard.css";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Header from "../../../Home/Header";
import VisaContext from "../../../../context/visa-context";
import { GoFileSubmodule } from "react-icons/go";
import { FaCcVisa } from "react-icons/fa6";
import { FaMagnifyingGlassArrowRight } from "react-icons/fa6";
import { HiOutlineDownload } from "react-icons/hi";

const Dashboard = () => {
  const userId = localStorage.getItem("userId");
  const ctxApi = useContext(VisaContext);
  const visasFiles = ctxApi.visasFiles;
  const ctxData = useContext(VisaContext);

  // **********************👍*********************
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  // *************👍*********
  return (
    <>
      <div className="ViaApply_Header dashboard_header">
        <Header />
      </div>
      <div className="container">
        <div className="main-container">
          <div className="main-title">
            <h3>DASHBOARD</h3>
          </div>

          <h3
            style={{ textAlign: "center", margin: "1rem 0rem", color: "red" }}
          >
            Tourist Visa
          </h3>
          <div className="main-cards">
            <div className="card_dashboard">
              <div className="card_dashboard-inner">
                <h3 style={{ color: "white" }}>Tourist Applications</h3>
                <Link
                  to={"/User-Applications"}
                  style={{
                    listStyle: "none",
                    fontSize: "25px",
                    color: "white",
                  }}
                >
                  <BsFillGrid3X3GapFill className=" card_dashboard_icon" />
                </Link>
              </div>
              <h1 style={{ color: "white" }}>
                {" "}
                {ctxData.applications ? ctxData.applications.length : 0}{" "}
              </h1>
            </div>
            <div className="card_dashboard">
              <div className="card_dashboard-inner">
                <h3 style={{ color: "white" }}>Download Files</h3>
                <Link
                  to={"/visasFiles"}
                  style={{
                    listStyle: "none",
                    fontSize: "25px",
                    color: "white",
                  }}
                >
                  <GoFileSubmodule className="card_dashboard_icon" />
                </Link>
              </div>
              <h1 style={{ color: "white" }}> {visasFiles.length} </h1>
            </div>
          </div>

          {/* ***************************Work Permit Visa****************************** */}
          <h3
            style={{ textAlign: "center", margin: "1rem 0rem", color: "red" }}
          >
            Work Permit Visa
          </h3>
          <div className="main-cards">
            <div className="card_dashboard">
              <div className="card_dashboard-inner">
                <h3 style={{ color: "white" }}>Work Permit Visa</h3>
                <Link
                  to={"/workPremitCards"}
                  style={{
                    listStyle: "none",
                    fontSize: "25px",
                    color: "white",
                  }}
                >
                  <FaMagnifyingGlassArrowRight className="card_dashboard_icon" />
                </Link>
              </div>
              <h1> </h1>
            </div>
            <div className="card_dashboard">
              <div className="card_dashboard-inner">
                <h3 style={{ color: "white" }}>Work Permit Applications</h3>
                <Link
                  to={"/workPermitVisas"}
                  style={{
                    listStyle: "none",
                    fontSize: "25px",
                    color: "white",
                  }}
                >
                  <FaCcVisa className="card_dashboard_icon" />
                </Link>
              </div>
              <h1 style={{ color: "white" }}>
                {" "}
                {ctxData.workVisas ? ctxData.workVisas.length : 0}{" "}
              </h1>
            </div>
          </div>
          {/* ***************************Book Add Onns****************************** */}
          <h3
            style={{ textAlign: "center", margin: "1rem 0rem", color: "red" }}
          >
            Travel Add Ons
          </h3>
          <div className="main-cards">
            <div className="card_dashboard">
              <div className="card_dashboard-inner">
                <h3 style={{ color: "white" }}>Travel Add Ons</h3>
                <Link
                  to={"/addonns"}
                  style={{
                    listStyle: "none",
                    fontSize: "25px",
                    color: "white",
                  }}
                >
                  <FaMagnifyingGlassArrowRight className="card_dashboard_icon" />
                </Link>
              </div>
              <h1> </h1>
            </div>
            <div className="card_dashboard">
              <div className="card_dashboard-inner">
                <h3 style={{ color: "white" }}>Download AddOns Files</h3>
                <Link
                  to={"/AddOns-DownloadFile"}
                  style={{
                    listStyle: "none",
                    fontSize: "25px",
                    color: "white",
                  }}
                >
                  <HiOutlineDownload className="card_dashboard_icon" />
                </Link>
              </div>
              <h1 style={{ color: "white" }}>
                {" "}
                {ctxData.addOnsFiles ? ctxData.addOnsFiles.length : 0}{" "}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
