import React, { useState } from 'react';
import "./forget.css";
import { useLocation, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { email } = state || {};
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match*');
      return;
    }

    try {
      const response = await fetch('https://backend-visa2.vercel.app/api/auth/resetPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, newPassword }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Error resetting password');
      }

      setMessage(data.message);
      alert(data.message);

      navigate('/'); // Navigate to login page or any other appropriate page
    } catch (error) {
      setMessage(error.message);
      alert('Error resetting password');
    }
  };

  return (
    <div className="requestPass">
      <form className='requestForm' onSubmit={handleSubmit}>
        <h2>Reset Password</h2>      
        <input
          className='inputsForget'
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Enter new password"
          required
        />
        <input
          className='inputsForget'
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm new password"
          required
        />
        {message && <p style={{color:"red",fontSize:"13px"}}>{message}</p>}
        <button className='submit_button' type="submit">Reset Password</button>
      </form>
      
    </div>
  );
};

export default ResetPassword;




