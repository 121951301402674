import React from "react";
import "./addons.css"

const AddOnsPaymentFail = () => {
  return (
    <>
      <div className="center-container">
        <h2 className="fail-text">Payment Fail</h2>
      </div>
    </>
  );
};

export default AddOnsPaymentFail;
