import React, { useState } from "react";
import "../footer.css";
import Header from "../../Home/Header";
import { Helmet } from "react-helmet";
import { validateFormFreeLancer } from "../../../util/validation";

const Freelancer = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    Cnomber: "",
    City: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmitFreelancer = async (e) => {
    e.preventDefault();
    // Validate the form data
    const newErrors = validateFormFreeLancer(data);
    setErrors(newErrors);

    // Check if there are any errors
    const isValid = Object.keys(newErrors).length > 0;

    if (!isValid) {
      try {
        const response = await fetch(
          "https://backend-visa2.vercel.app/api/Freelancer",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        if (response.ok) {
          const res = await response.json();
          alert("Your form has been successfully submitted.");
        } else {
          const errorData = response.json();
          console.log("errorData", errorData);
        }
      } catch (error) {
        console.log(error);
      }
      setData({
        name: "",
        email: "",
        Cnomber: "",
        City: "",
      });
    } 
  };


  const currentUrl = window.location.href;
  return (
    <>
      <div className="ViaApply_Header trevellerHead">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Freelancers-Page</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>
      <div className="Freelacer">
        <div className="container">
          <h3>Freelancer Form</h3>
          <div className="Freelancer_form55">
            <form className="Freelancer-form">
              <h4>
                Join our freelancing team. We provide free practical visa
                councelling and filing course. Fill these form if interested.
              </h4>
              <div className="row ">
                <div className="md_form_Freelance col-lg-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=" Name"
                    value={data.name}
                    name="name"
                    onChange={handleChange}
                  />
                  {errors.name && <p className="style01">{errors.name}</p>}
                </div>
                <div className="md_form_Freelance mdForm_margin col-lg-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=" Contact No."
                    value={data.Cnomber}
                    name="Cnomber"
                    onChange={handleChange}
                  />
                  {errors.Cnomber && (
                    <p className="style01">{errors.Cnomber}</p>
                  )}
                </div>
              </div>
              <div className="row ">
                <div className="md_form_Freelance mdForm_margin col-lg-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=" Email"
                    value={data.email}
                    name="email"
                    onChange={handleChange}
                  />
                  {errors.email && <p className="style01">{errors.email}</p>}
                </div>
                <div className="md_form_Freelance mdForm_margin col-lg-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                    value={data.City}
                    name="City"
                    onChange={handleChange}
                  />
                  {errors.City && <p className="style01">{errors.City}</p>}
                </div>
              </div>
              <div className="submit-button">
                <button onClick={handleSubmitFreelancer}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Freelancer;
