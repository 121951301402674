import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const FaliureApplicant = () => {
    const Navigate = useNavigate();
    const handleClickFail = () => {
      Navigate("/workPremitCards");
    };
  return (
    <>
           <div className="payment_main_page">
        <div className="payment_fail_page">
          <div className="payment_fail_images">
            <img src="/NewImage/Payment-Failed.jpeg" alt="Payment failed img" />
          </div>
          <h3>Payment Failed!</h3>
          <p>
            Please click on OK button and go to checkout page and again complete
            the payment.
          </p>
          <div className="payment_fail_button">
            <Button variant="contained" size="small" onClick={handleClickFail}>
              Ok
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default FaliureApplicant
