import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const ApplicantPaymentSucces = () => {
  const [workVisa, setWorkVisa] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedData = sessionStorage.getItem("workVisa");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (Array.isArray(parsedData) && parsedData.length > 0) {
        setWorkVisa(parsedData);
      }
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(workVisa) && workVisa.length > 0) {
      const item = workVisa[0];
      if (isFormDataValid(workVisa)) {
        saveApplicationData();
      } else {
        console.log(
          "Data is invalid or missing. Not calling saveApplicationData."
        );
      }
    } else {
      console.log("workVisa is not an array or is empty.");
    }
  }, [workVisa]);

  const isFormDataValid = (formData) => {
    if (Array.isArray(formData) && formData.length > 0) {
      const item = formData[0]; // Access the first item in the array
      return item.name && item.lname && item.countryName &&  item.countryPrice && item.ApplyDate && item.userId && item.id;
    }
    return false;
  };

  const saveApplicationData = async () => {
    if (!workVisa || !Array.isArray(workVisa) || workVisa.length === 0) {
      console.error("workVisa is empty or undefined");
      return;
    }
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/applicant/workVisaApplication",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(workVisa), // Ensure postData is defined and structured correctly
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status frontend: ${response.status}`);
      }
      // Remove postData from sessionStorage
      console.log("Data saved successfully!");
      sessionStorage.removeItem("workVisa");
      sessionStorage.removeItem("workVisaId");
      const responseData = await response.json();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleNext = () => {
    navigate("/");
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="failure_page55">
        <div className="payment-success-img">
          <img src="/NewImage/payment-successful.webp" alt="payment-image" />
        </div>
        <div className="centered-element">
          <div className="otp_verify">
            <h2>Payment Successfull!</h2>
          </div>
          <Button
            variant="contained"
            size="small"
            className="buton_payment"
            onClick={handleNext}
          >
            OK
          </Button>
        </div>
      </div>
    </>
  );
};

export default ApplicantPaymentSucces;
