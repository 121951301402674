import React, { useState, useEffect, useContext } from "react";
import { countries, airPortCityList } from "../../../../countries";
import Header from "../../../../Home/Header";
import Select from "react-select";
import "./addons.css";
import Form from "react-bootstrap/Form";
import { GiCommercialAirplane } from "react-icons/gi";
import axios from "axios";
// ********************************CALLENDAR**********************************
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import VisaContext from "../../../../../context/visa-context";
const currentDate = dayjs();

const AddOnns = () => {
  const userId = localStorage.getItem("userId");
  const ctx = useContext(VisaContext);
  const Usres = ctx.users;
  //  *********show user name  👍*****************
  const RegisterUsers = Usres.filter((app) => {
    return app._id === userId;
  });
  const RegisterUsersName =
    RegisterUsers.length > 0 ? RegisterUsers[0].name : "";
    sessionStorage.setItem("userName",RegisterUsersName);
    const userName = sessionStorage.getItem("userName");

  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [isOneWay, setIsOneWay] = useState(true); // By default, One-Way is selected
  const [flight, setFlight] = useState({
    userId: userId || "",
    AoType: "Flight Ticket",
    oneway: true,
    twoway: false,
    from: "",
    to: "",
    dDate: "",
    rDate: "",
    name: "",
    passportNo: "",
    client: userName || "",
    price: "999",
  });

  const [hotel, setHotel] = useState({
    userId: userId || "",
    AoType: "Hotel",
    name: "",
    passportNo: "",
    price: "499",
    client: userName || "",
    cCity: "",
    rating: "",
  });
  const [cLetter, setCLetter] = useState({
    userId: userId || "",
    AoType: "Cover Letter",
    name: "",
    passportNo: "",
    price: "499",
    client: userName || "",
  });
  const [itinerary, setItinerary] = useState({
    userId: userId || "",
    AoType: "Itinerary",
    name: "",
    passportNo: "",
    price: "499",
    client: userName || "",
    cCity: "",
  });

  useEffect(() => {
    // Map countries to have 'label' and 'value'
    const countryOptions = countries.map((country) => ({
      label: country.name, // Display name in the dropdown
      value: country.name, // Value to be stored
    }));
    setCountryList(countryOptions);
  }, [countries]);
  // ariPortCity List
  useEffect(() => {
    const cityOptions = airPortCityList.map((city) => ({
      label: city, // Display name in the dropdown
      value: city, // Value to be stored
    }));
    setCityList(cityOptions);
  }, [airPortCityList]);

  // Handle country selection
  const handleCountrySelect = (selectedOption) => {
    setSelectedCountry(selectedOption); // The entire selected object
    setFlight({
      ...flight,
      to: selectedOption.value,
    });
  };
  // Handle country selection
  const handleCitySelect = (selectedCity) => {
    setSelectedCity(selectedCity); // The entire selected object
    setFlight({
      ...flight,
      from: selectedCity.value,
    });
  };
  // ****************One-way two-way*************
  const handleOneWaySelect = () => {
    setIsOneWay(true);
    setFlight({
      ...flight,
      oneway: true,
      twoway: false,
      price: "999",
    });
  };
  const handleTwoWaySelect = () => {
    setIsOneWay(false);
    setFlight({
      ...flight,
      oneway: false,
      twoway: true,
      price: "1998",
    });
  };

  // Handle departure date selection (dDate) and format as DD/MM/YY
  const handleDepartureDateChange = (date) => {
    setFlight({
      ...flight,
      dDate: date ? dayjs(date).format("DD/MM/YY") : "", // Format the date before saving
    });
  };

  // Handle return date selection (rDate) and format as DD/MM/YY
  const handleReturnDateChange = (date) => {
    setFlight({
      ...flight,
      rDate: date ? dayjs(date).format("DD/MM/YY") : "", // Format the date before saving
    });
  };

  // Update client field after userName is set in sessionStorage
useEffect(() => {
  if (userName) {
    setFlight((prev) => ({ ...prev, client: userName }));
    setHotel((prev) => ({ ...prev, client: userName }));
    setCLetter((prev) => ({ ...prev, client: userName }));
    setItinerary((prev) => ({ ...prev, client: userName }));
  }
}, [userName]);


  // ******************************payment gateway start******************************
const initiatePhonePePayment = async (e,amount,type) => {
  e.preventDefault();
  // Save form data to localStorage before payment
    // Clear any previously stored data
    sessionStorage.removeItem("FlightData");
    sessionStorage.removeItem("HotelData");
    sessionStorage.removeItem("CoverLetterData");
    sessionStorage.removeItem("ItineraryData");
  
    // Save specific data based on the type
    switch (type) {
      case "flight":
        sessionStorage.setItem("FlightData", JSON.stringify(flight));
        break;
      case "hotel":
        sessionStorage.setItem("HotelData", JSON.stringify(hotel));
        break;
      case "coverLetter":
        sessionStorage.setItem("CoverLetterData", JSON.stringify(cLetter));
        break;
      case "itinerary":
        sessionStorage.setItem("ItineraryData", JSON.stringify(itinerary));
        break;
      default:
        console.error("Unknown payment type");
        return;
    }
  try {
    const res = await axios.post(
      "https://backend-visa2.vercel.app/api/addOns/initiate-payment",
      { amount: amount, }
    );

    if (res.data && res.data.data.instrumentResponse.redirectInfo.url) {
      window.location.href =
        res.data.data.instrumentResponse.redirectInfo.url;
    }
  } catch (error) {
    if (error.response) {
      console.error("Response error details:", error.response.data);
    } else {
      console.error("Error in payment gateway:", error.message);
    }
  }
};

  return (
    <>
      <div className="ViaApply_Header dashboard_header">
        <Header />
      </div>
      <div className="main_wrapperAddons_page">
        <div className="container">
          <h2
            style={{ color: "red", textAlign: "center", margin: "1.5rem 0rem" }}
          >
            Add Ons
          </h2>
          <div className="row">
            <div className="col-lg-6">
              <div className="Flight_Tickets card">
                <div className="airplaneimage_img">
                  <img src="/NewImage/Airplane-Vector.png" alt="" />
                  <img src="/NewImage/Airplane-Vector.png" alt="" />
                </div>
                <h3 className="flight_mainHeadin_airplane">
                  {" "}
                  Dummy Flight{" "}
                  <strong>
                    <GiCommercialAirplane />
                  </strong>{" "}
                  Ticket{" "}
                </h3>
                <div className="oneway_twoway">
                  <h5>
                    One-Way{" "}
                    <span>
                      <Form.Check
                        type="radio"
                        aria-label="radio"
                        checked={isOneWay}
                        onChange={handleOneWaySelect}
                      />
                    </span>
                  </h5>
                  <h5>
                    Two-Way{" "}
                    <span>
                      <Form.Check
                        type="radio"
                        aria-label="radio 1"
                        checked={!isOneWay}
                        onChange={handleTwoWaySelect}
                      />
                    </span>
                  </h5>
                </div>
                <div className="flight_fromTo">
                  <div className="dropdown-btn">
                    <Select
                      key={cityList.value}
                      options={cityList}
                      value={selectedCity}
                      onChange={handleCitySelect}
                      placeholder={"From"}
                    />
                  </div>
                  <div className="dropdown-btn">
                    <Select
                      key={countryList.value}
                      options={countryList}
                      value={selectedCountry}
                      onChange={handleCountrySelect}
                      placeholder={"To"}
                    />
                  </div>
                </div>
                <div className="DatesFor_flight">
                  <div className="departurDate main_departurDate1">
                    <div className="expected_travelDate md-form mdForm_margin col-lg-6">
                      <label className="doblable">Departure Date</label>
                      <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker
                          minDate={currentDate}
                          // key={traveler.id}
                          className="datetm4 "
                          value={
                            flight.dDate
                              ? dayjs(flight.dDate, "DD/MM/YY")
                              : null
                          }
                          onChange={(date) => handleDepartureDateChange(date)}
                          format="DD/MM/YYYY"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="DOB" />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="departurDate main_departurDate2">
                    <div className="expected_travelDate md-form mdForm_margin col-lg-6">
                      <label className="doblable">Return Date</label>
                      <LocalizationProvider dateAdapter={AdapterDayjs} className="custom-date-input" >
                        <DatePicker
                          minDate={currentDate}
                          className="datetm4 custom-date-input"
                          format="DD/MM/YYYY"
                          value={
                            flight.rDate
                              ? dayjs(flight.rDate, "DD/MM/YY")
                              : null
                          }
                          onChange={(date) => handleReturnDateChange(date)}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="DOB" />
                          )}
                          disabled={isOneWay} // Disable DatePicker if One-Way is selected
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="flight_traveler_name">
                  <div className="flightTravelName">
                    <input
                      type="text"
                      placeholder="Name"
                      onChange={(e) =>
                        setFlight({ ...flight, name: e.target.value })
                      }
                    />
                    <input
                      type="text"
                      placeholder="Passport No."
                      onChange={(e) =>
                        setFlight({ ...flight, passportNo: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="dummyflight_priceAndtraveler">
                  <p className="dummy_FPrice">
                    Price: <strong>{flight.price} </strong>
                  </p>
                </div>
                <div className="dummyflight_priceAndtraveler">
                  <button className="FPrice_button" onClick={(e)=>initiatePhonePePayment(e,flight.price,"flight")}>
                    Buy Now{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="coverLetter_card  card">
                <div className="mainCard_CoverLetter">
                  <div className="mainHeading_coverLetter">
                    <h3>Hotel Booking</h3>
                    <div className="img_coverLetter">
                      <img
                        src="/NewImage/hotel-reception-arrival.avif"
                        alt="cover-letter-img"
                      />
                    </div>
                    <div className="flightTravelName flightCoverLetter">
                      <input
                        type="text"
                        placeholder="Name"
                        onChange={(e) =>
                          setHotel({ ...hotel, name: e.target.value })
                        }
                      />
                      <input
                        type="text"
                        placeholder="Passport No."
                        onChange={(e) =>
                          setHotel({ ...hotel, passportNo: e.target.value })
                        }
                      />
                    </div>
                    <div className="flightTravelName flightCoverLetter setHotelRating">
                      <input
                        type="text"
                        placeholder="Country/City"
                        onChange={(e) =>
                          setHotel({ ...hotel, cCity: e.target.value })
                        }
                      />
                      <Form.Select
                        aria-label="Default select example"
                        size="sm"
                        onChange={(e) =>
                          setHotel({ ...hotel, rating: e.target.value })
                        }
                      >
                        <option>Star ⭐</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="3">4</option>
                        <option value="3">5</option>
                      </Form.Select>
                    </div>
                    <div className="dummyflight_priceAndtraveler">
                      <p className="dummy_FPrice">
                        Price: <strong>499 </strong>
                      </p>
                    </div>
                    <div className="dummyflight_priceAndtraveler">
                      <button
                        className="FPrice_button"
                        onClick={(e)=>initiatePhonePePayment(e,hotel.price,"hotel")}
                      >
                        Buy Now{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="coverLetter_card coverLetter_card11 card">
                <div className="mainCard_CoverLetter">
                  <div className="mainHeading_coverLetter">
                    <h3>Cover Letter</h3>
                    <div className="img_coverLetter">
                      <img
                        src="/NewImage/Cover-letter.webp"
                        alt="cover-letter-img"
                      />
                    </div>
                    <div className="flightTravelName flightCoverLetter">
                      <input
                        type="text"
                        placeholder="Name"
                        onChange={(e) =>
                          setCLetter({ ...cLetter, name: e.target.value })
                        }
                      />
                      <input
                        type="text"
                        placeholder="Passport No."
                        onChange={(e) =>
                          setCLetter({ ...cLetter, passportNo: e.target.value })
                        }
                      />
                    </div>
                    <div className="dummyflight_priceAndtraveler">
                      <p className="dummy_FPrice">
                        Price: <strong>499 </strong>
                      </p>
                    </div>
                    <div className="dummyflight_priceAndtraveler">
                      <button
                        className="FPrice_button"
                        onClick={(e)=>initiatePhonePePayment(e,cLetter.price,"coverLetter")}
                      >
                        Buy Now{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="coverLetter_card card">
                <div className="mainCard_CoverLetter">
                  <div className="mainHeading_coverLetter">
                    <h3>Itinerary</h3>
                    <div className="img_coverLetter">
                      <img
                        src="/NewImage/create-your-travel-itinerary.png"
                        alt="cover-letter-img"
                      />
                    </div>
                    <div className="flightTravelName flightCoverLetter">
                      <input
                        type="text"
                        placeholder="Name"
                        onChange={(e) =>
                          setItinerary({ ...itinerary, name: e.target.value })
                        }
                      />
                      <input
                        type="text"
                        placeholder="Passport No."
                        onChange={(e) =>
                          setItinerary({
                            ...itinerary,
                            passportNo: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flightTravelName flightCoverLetter  setItenerary">
                      <input
                        type="text"
                        placeholder="Country"
                        onChange={(e) =>
                          setItinerary({ ...itinerary, cCity: e.target.value })
                        }
                      />
                      <input
                        type="text"
                        placeholder="Name"
                        style={{ visibility: "hidden" }}
                      />
                    </div>
                    <div className="dummyflight_priceAndtraveler">
                      <p className="dummy_FPrice">
                        Price: <strong>499 </strong>
                      </p>
                    </div>
                    <div className="dummyflight_priceAndtraveler">
                      <button className="FPrice_button" onClick={(e)=>initiatePhonePePayment(e,itinerary.price,"itinerary")}>Buy Now </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOnns;
